import ContentMenu from "./ContentMenu"
import { useState } from "react"
import Modal from "./Modal"
import { useModal } from "../utils/hooks/useModal"

function DetailLayout({contentMenu, modalData, content}) {

    const {currentModalData, showModal, modalOpen, modalClose, modalSave} = useModal()

    const handleClick = (button) => {
        if(button.modal && button.modalDataKey)
        {
            modalOpen(modalData[button.modalDataKey])
        }
        else if(button.clickHandler)
        {
            button.clickHandler()
        }
    }

    return (
        <>
            <ContentMenu backUrl={contentMenu.backUrl} backText={contentMenu.backText} buttons={contentMenu.buttons}/>
            {content}
            {modalData && 
                <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >
                    {currentModalData ? currentModalData.data : ""}
                </Modal>
            }
        </>
    )
}

export default DetailLayout