import BasicEditForm from '../BasicEditForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';

const url = "/" + process.env.REACT_APP_CLUB + "/events/prizetypes"

function EditPrizeTypeForm() {
    return(
      <BasicEditForm url={url} titleFieldName={"name"} primaryKeyName={"id"}>
        <FormRow>
          <FormTextInput width="10" name="name" placeholder="Prize Type Name" label="* Prize Type Name" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Type Name" defaultValueField="name"/>     
        </FormRow>
      </BasicEditForm>
    )
}

export default EditPrizeTypeForm