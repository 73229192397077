import { Link, Outlet, NavLink} from "react-router-dom";
import ALink from "../../components/ALink";
import ProtectedNavLink from "../../components/ProtectedNavLink";

function MembersHome(){
    return (
    <>
        <nav className="nav-sub-header">
            <div className="left-header">
                <NavLink to="/home/members/" end>Member Details</NavLink>
                <ProtectedNavLink permission_max_level={2}><NavLink to="/home/members/handicaptypes">Handicap Disciplines</NavLink></ProtectedNavLink>
                <NavLink to="/home/members/handicaps">Member Handicaps</NavLink>
            </div>
        </nav>
        
        <div className="content">
            <Outlet />
        </div>
        
    </>
    );
}

export default MembersHome