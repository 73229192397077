import "./users/css/sign-in.css"
import { LoginForm } from "../components/LoginForm";
import { useAuth } from "../utils/hooks/useAuth";
import { Navigate } from "react-router-dom";
import getme from "../api/getme";
import { useNavigate } from "react-router-dom";

function Login(){
    const {user, logout} = useAuth()
    const navigate = useNavigate()

    if(user)
    {
        getme().then(
            (response) => {
                if(response)
                {
                    navigate("/home")
                }
                else
                {
                    logout()
                }
            }
        )
        
    }

    return (
        <>
        <LoginForm />
        </>
    );
}

export default Login