import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormSeparator from '../FormSeparator';
import FormCheckBox from '../FormCheckBox';
import OnClickButton from '../../OnClickButton';
import { Fragment, useState } from 'react';
import FormSelect from '../FormSelect';
import Col from 'react-bootstrap/Col';

function AddOffRiflePrizeTemplateForm({prize_type_data=[]}) {
    const [overallWinnerToggle, setOverallWinnerToggle] = useState(false)
    const [overallWinnerPrizes, setOverallWinnerPrizes] = useState([])

    const [overallPlacingsToggle, setOverallPlacingsToggle] = useState(false)
    const [overallPlacingPositionPrizes, setOverallPlacingPositionPrizes] = useState([])
    const [overallPlacingIncrementPrizes, setOverallPlacingIncrementPrizes] = useState([])

    const [gradedWinnerToggle, setGradedWinnerToggle] = useState(false)
    const [gradedWinnerPrizes, setGradedWinnerPrizes] = useState([])

    const [gradedPlacingsToggle, setGradedPlacingsToggle] = useState(false)
    const [gradedPlacingPositionPrizes, setGradedPlacingPositionPrizes] = useState([])
    const [gradedPlacingIncrementPrizes, setGradedPlacingIncrementPrizes] = useState([])

    function onClickAddPrize(event, type)
    {
        if(type == "ow")
            setOverallWinnerPrizes([...overallWinnerPrizes, {"prize_type_id": 1, "prize_type_value": "0", "prize_type_value_type": "int"}])
        if(type == "op_position")
            setOverallPlacingPositionPrizes([...overallPlacingPositionPrizes, {"position": "0", "prize_type_id": 1, "prize_type_value": "0", "prize_type_value_type": "int"}])
        if(type == "op_increment")
            setOverallPlacingIncrementPrizes([...overallPlacingIncrementPrizes, {"top_num": "0", "top_percent": "0", "prize_type_id": 1, "prize_type_value": "0", "prize_type_value_type": "int"}])
        
        if(type == "gw")
            setGradedWinnerPrizes([...gradedWinnerPrizes, {"prize_type_id": 1, "prize_type_value": "0", "prize_type_value_type": "int"}])
        if(type == "gp_position")
            setGradedPlacingPositionPrizes([...gradedPlacingPositionPrizes, {"position": "0", "prize_type_id": 1, "prize_type_value": "0", "prize_type_value_type": "int"}])
        if(type == "gp_increment")
            setGradedPlacingIncrementPrizes([...gradedPlacingIncrementPrizes, {"top_num": "0", "top_percent": "0", "prize_type_id": 1, "prize_type_value": "0", "prize_type_value_type": "int"}])
    }

    function onClickDeletePrize(event, _index, prizesArray, prizesArraySet)
    {
        let ret_arr = prizesArray.filter((item, index) => {return index !== _index})
        prizesArraySet(ret_arr)
    }

    const onChangePrizes = (event, index, type, prizesArray, prizesArraySet, prizes_prefix) => {
        let form = document.getElementById("modalForm")
        const new_prizes = prizesArray.map((prize, c_index) => {
            if(index == c_index)
            {
                if(type == "winner")
                    return {"prize_type_id": form.elements[prizes_prefix + "prize_type_id_" + index].value, "prize_type_value": form.elements[prizes_prefix + "prize_type_value_" + index].value, "prize_type_value_type": form.elements[prizes_prefix + "prize_type_value_type_" + index].value}
                if(type == "position")
                    return {"position": form.elements[prizes_prefix + "position_" + index].value, "prize_type_id": form.elements[prizes_prefix + "prize_type_id_" + index].value, "prize_type_value": form.elements[prizes_prefix + "prize_type_value_" + index].value, "prize_type_value_type": form.elements[prizes_prefix + "prize_type_value_type_" + index].value}
                if(type == "increment")
                    return {"top_num": form.elements[prizes_prefix + "top_num_" + index].value, "top_percent": form.elements[prizes_prefix + "top_percent_" + index].value,"prize_type_id": form.elements[prizes_prefix + "prize_type_id_" + index].value, "prize_type_value": form.elements[prizes_prefix + "prize_type_value_" + index].value, "prize_type_value_type": form.elements[prizes_prefix + "prize_type_value_type_" + index].value}
            }
            else
            {
                return prize
            }
        })
        prizesArraySet(new_prizes)
    }

    return(
        <BasicAddForm>
        <FormRow>
            <FormTextInput width="10" name="name" placeholder="Prize Template Name" label="* Prize Template Name" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Template Name" data="sdf"/>
        </FormRow>

        <FormSeparator />
        <FormRow>
            <FormCheckBox name="overall_winner" label="Overall Winner" onChange={(e) => setOverallWinnerToggle(e.target.checked)}/>
        </FormRow>
        <FormRow>
            <>
            {
                overallWinnerToggle &&
                <>
                {overallWinnerPrizes.map((prize, index) => {
                    return(
                    <FormRow key={"ow_prizes_" + index} data={prize}>
                        <FormSelect width="3" name={"ow_prize_type_id_" + index} placeholder="Prize Type" label="* Prize Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type" value={prize["prize_type_id"]} onChange={(e) => {onChangePrizes(e, index, "winner", overallWinnerPrizes, setOverallWinnerPrizes, "ow_")}}>
                            {
                                prize_type_data &&
                                prize_type_data.map((prize_type) => {
                                    return(
                                        <option key={prize_type.id} value={prize_type.id}>{prize_type.name}</option>
                                    )
                                })
                            }
                        </FormSelect>
                        <FormTextInput width="3" name={"ow_prize_type_value_" + index} placeholder="Value" label="* Value" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Type Value" value={prize["prize_type_value"]} onChange={(e) => {onChangePrizes(e, index, "winner", overallWinnerPrizes, setOverallWinnerPrizes, "ow_")}}/>
                        <FormSelect width="3" name={"ow_prize_type_value_type_" + index} placeholder="Value Type" label="* Value Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type Value Type" value={prize["prize_type_value_type"]} onChange={(e) => {onChangePrizes(e, index, "winner", overallWinnerPrizes, setOverallWinnerPrizes, "ow_")}}>
                            <option value="int">Integer</option>
                            <option value="float">Float</option>
                            <option value="string">String</option>
                        </FormSelect>
                        <div className="col-md-2" style={{marginTop: "22px"}}><OnClickButton symbolClassName={"fas"} text="" symbol="&#xf2ed;" onClickHandler={(e) => {e.preventDefault();  onClickDeletePrize(e, index, overallWinnerPrizes, setOverallWinnerPrizes)}}/> </div>
                    </FormRow>
                    )
                })}
                <div>
                    <OnClickButton text="+ Add Overall Winner Prize" onClickHandler={(e) => onClickAddPrize(e, "ow")}/>
                </div>
                </>
            }
            </>
        </FormRow>

        <FormSeparator />
        <FormRow>
            <FormCheckBox name="overall_placings" label="Overall Placings" onChange={(e) => setOverallPlacingsToggle(e.target.checked)}/>
        </FormRow>
        <FormRow>
            <>
            {
                overallPlacingsToggle &&
                <>
                {overallPlacingPositionPrizes.map((prize, index) => {
                    return(
                    <Fragment key={"op_position_prizes1_" + index}>
                    <FormRow data={prize}>
                        <FormTextInput width="3" name={"op_position_position_" + index} placeholder="Position" label="* Position" required={true} feedbackType="invalid" feedbackText="Please enter a Position Number" value={prize["position"]} onChange={(e) => {onChangePrizes(e, index, "position", overallPlacingPositionPrizes, setOverallPlacingPositionPrizes, "op_position_")}}/>
                    </FormRow>
                    <FormRow data={prize}>
                        <FormSelect width="3" name={"op_position_prize_type_id_" + index} placeholder="Prize Type" label="* Prize Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type" value={prize["prize_type_id"]} onChange={(e) => {onChangePrizes(e, index, "position", overallPlacingPositionPrizes, setOverallPlacingPositionPrizes, "op_position_")}}>
                            {
                                prize_type_data &&
                                prize_type_data.map((prize_type) => {
                                    return(
                                        <option key={prize_type.id} value={prize_type.id}>{prize_type.name}</option>
                                    )
                                })
                            }
                        </FormSelect>
                        <FormTextInput width="3" name={"op_position_prize_type_value_" + index} placeholder="Value" label="* Value" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Type Value" value={prize["prize_type_value"]} onChange={(e) => {onChangePrizes(e, index, "position", overallPlacingPositionPrizes, setOverallPlacingPositionPrizes, "op_position_")}}/>
                        <FormSelect width="3" name={"op_position_prize_type_value_type_" + index} placeholder="Value Type" label="* Value Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type Value Type" value={prize["prize_type_value_type"]} onChange={(e) => {onChangePrizes(e, index, "position", overallPlacingPositionPrizes, setOverallPlacingPositionPrizes, "op_position_")}}>
                            <option value="int">Integer</option>
                            <option value="float">Float</option>
                            <option value="string">String</option>
                        </FormSelect>
                        <div className="col-md-2" style={{marginTop: "22px"}}><OnClickButton symbolClassName={"fas"} text="" symbol="&#xf2ed;" onClickHandler={(e) => {e.preventDefault();  onClickDeletePrize(e, index, overallPlacingPositionPrizes, setOverallPlacingPositionPrizes)}}/> </div>
                    </FormRow>
                    </Fragment>
                    )
                })}
                <div>
                    <OnClickButton text="+ Add Overall Placing Position Prize" onClickHandler={(e) => onClickAddPrize(e, "op_position")}/>
                </div>
                </>
            }
            </>
        </FormRow>
        <FormRow>
            <>
            {
                overallPlacingsToggle &&
                <>
                {overallPlacingIncrementPrizes.map((prize, index) => {
                    return(
                    <Fragment key={"op_increment_prizes1_" + index}>
                    <FormRow data={prize}>
                        <FormTextInput width="3" name={"op_increment_top_num_" + index} placeholder="Top #" label="* Top #" required={true} feedbackType="invalid" feedbackText="Please enter a Top Number of Competitors" value={prize["top_num"]} onChange={(e) => {onChangePrizes(e, index, "increment", overallPlacingIncrementPrizes, setOverallPlacingIncrementPrizes, "op_increment_")}}/>
                        <FormTextInput width="3" name={"op_increment_top_percent_" + index} placeholder="Top %" label="* Top %" required={true} feedbackType="invalid" feedbackText="Please enter a Top Percent of Competitors" value={prize["top_percent"]} onChange={(e) => {onChangePrizes(e, index, "increment", overallPlacingIncrementPrizes, setOverallPlacingIncrementPrizes, "op_increment_")}}/>
                    </FormRow>
                    <FormRow data={prize}>
                        <FormSelect width="3" name={"op_increment_prize_type_id_" + index} placeholder="Prize Type" label="* Prize Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type" value={prize["prize_type_id"]} onChange={(e) => {onChangePrizes(e, index, "increment", overallPlacingIncrementPrizes, setOverallPlacingIncrementPrizes, "op_increment_")}}>
                            {
                                prize_type_data &&
                                prize_type_data.map((prize_type) => {
                                    return(
                                        <option key={prize_type.id} value={prize_type.id}>{prize_type.name}</option>
                                    )
                                })
                            }
                        </FormSelect>
                        <FormTextInput width="3" name={"op_increment_prize_type_value_" + index} placeholder="Value" label="* Value" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Type Value" value={prize["prize_type_value"]} onChange={(e) => {onChangePrizes(e, index, "increment", overallPlacingIncrementPrizes, setOverallPlacingIncrementPrizes, "op_increment_")}}/>
                        <FormSelect width="3" name={"op_increment_prize_type_value_type_" + index} placeholder="Value Type" label="* Value Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type Value Type" value={prize["prize_type_value_type"]} onChange={(e) => {onChangePrizes(e, index, "increment", overallPlacingIncrementPrizes, setOverallPlacingIncrementPrizes, "op_increment_")}}>
                            <option value="int">Integer</option>
                            <option value="float">Float</option>
                            <option value="string">String</option>
                        </FormSelect>
                        <div className="col-md-2" style={{marginTop: "22px"}}><OnClickButton symbolClassName={"fas"} text="" symbol="&#xf2ed;" onClickHandler={(e) => {e.preventDefault();  onClickDeletePrize(e, index, overallPlacingIncrementPrizes, setOverallPlacingIncrementPrizes)}}/> </div>
                    </FormRow>
                    </Fragment>
                    )
                })}
                <div>
                    <OnClickButton text="+ Add Overall Placing Increment Prize" onClickHandler={(e) => onClickAddPrize(e, "op_increment")}/>
                </div>
                </>
            }
            </>
        </FormRow>



        <FormSeparator />
        <FormRow>
            <FormCheckBox name="graded_winners" label="Graded Winners" onChange={(e) => setGradedWinnerToggle(e.target.checked)}/>
        </FormRow>
        <FormRow>
            <>
            {
                gradedWinnerToggle &&
                <>
                {gradedWinnerPrizes.map((prize, index) => {
                    return(
                    <FormRow key={"gw_prizes_" + index} data={prize}>
                        <FormSelect width="3" name={"gw_prize_type_id_" + index} placeholder="Prize Type" label="* Prize Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type" value={prize["prize_type_id"]} onChange={(e) => {onChangePrizes(e, index, "winner", gradedWinnerPrizes, setGradedWinnerPrizes, "gw_")}}>
                            {
                                prize_type_data &&
                                prize_type_data.map((prize_type) => {
                                    return(
                                        <option key={prize_type.id} value={prize_type.id}>{prize_type.name}</option>
                                    )
                                })
                            }
                        </FormSelect>
                        <FormTextInput width="3" name={"gw_prize_type_value_" + index} placeholder="Value" label="* Value" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Type Value" value={prize["prize_type_value"]} onChange={(e) => {onChangePrizes(e, index, "winner", gradedWinnerPrizes, setGradedWinnerPrizes, "gw_")}}/>
                        <FormSelect width="3" name={"gw_prize_type_value_type_" + index} placeholder="Value Type" label="* Value Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type Value Type" value={prize["prize_type_value_type"]} onChange={(e) => {onChangePrizes(e, index, "winner", gradedWinnerPrizes, setGradedWinnerPrizes, "gw_")}}>
                            <option value="int">Integer</option>
                            <option value="float">Float</option>
                            <option value="string">String</option>
                        </FormSelect>
                        <div className="col-md-2" style={{marginTop: "22px"}}><OnClickButton symbolClassName={"fas"} text="" symbol="&#xf2ed;" onClickHandler={(e) => {e.preventDefault();  onClickDeletePrize(e, index, gradedWinnerPrizes, setGradedWinnerPrizes)}}/> </div>
                    </FormRow>
                    )
                })}
                <div>
                    <OnClickButton text="+ Add Graded Winner Prize" onClickHandler={(e) => onClickAddPrize(e, "gw")}/>
                </div>
                </>
            }
            </>
        </FormRow>

        <FormSeparator />
        <FormRow>
            <FormCheckBox name="graded_placings" label="Graded Placings" onChange={(e) => setGradedPlacingsToggle(e.target.checked)}/>
        </FormRow>
        <FormRow>
            <>
            {
                gradedPlacingsToggle &&
                <>
                {gradedPlacingPositionPrizes.map((prize, index) => {
                    return(
                    <Fragment key={"gp_position_prizes1_" + index}>
                    <FormRow data={prize}>
                        <FormTextInput width="3" name={"gp_position_position_" + index} placeholder="Position" label="* Position" required={true} feedbackType="invalid" feedbackText="Please enter a Position Number" value={prize["position"]} onChange={(e) => {onChangePrizes(e, index, "position", gradedPlacingPositionPrizes, setGradedPlacingPositionPrizes, "gp_position_")}}/>
                    </FormRow>
                    <FormRow data={prize}>
                        <FormSelect width="3" name={"gp_position_prize_type_id_" + index} placeholder="Prize Type" label="* Prize Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type" value={prize["prize_type_id"]} onChange={(e) => {onChangePrizes(e, index, "position", gradedPlacingPositionPrizes, setGradedPlacingPositionPrizes, "gp_position_")}}>
                            {
                                prize_type_data &&
                                prize_type_data.map((prize_type) => {
                                    return(
                                        <option key={prize_type.id} value={prize_type.id}>{prize_type.name}</option>
                                    )
                                })
                            }
                        </FormSelect>
                        <FormTextInput width="3" name={"gp_position_prize_type_value_" + index} placeholder="Value" label="* Value" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Type Value" value={prize["prize_type_value"]} onChange={(e) => {onChangePrizes(e, index, "position", gradedPlacingPositionPrizes, setGradedPlacingPositionPrizes, "gp_position_")}}/>
                        <FormSelect width="3" name={"gp_position_prize_type_value_type_" + index} placeholder="Value Type" label="* Value Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type Value Type" value={prize["prize_type_value_type"]} onChange={(e) => {onChangePrizes(e, index, "position", gradedPlacingPositionPrizes, setGradedPlacingPositionPrizes, "gp_position_")}}>
                            <option value="int">Integer</option>
                            <option value="float">Float</option>
                            <option value="string">String</option>
                        </FormSelect>
                        <div className="col-md-2" style={{marginTop: "22px"}}><OnClickButton symbolClassName={"fas"} text="" symbol="&#xf2ed;" onClickHandler={(e) => {e.preventDefault();  onClickDeletePrize(e, index, gradedPlacingPositionPrizes, setGradedPlacingPositionPrizes)}}/> </div>
                    </FormRow>
                    </Fragment>
                    )
                })}
                <div>
                    <OnClickButton text="+ Add Graded Placing Position Prize" onClickHandler={(e) => onClickAddPrize(e, "gp_position")}/>
                </div>
                </>
            }
            </>
        </FormRow>
        <FormRow>
            <>
            {
                gradedPlacingsToggle &&
                <>
                {gradedPlacingIncrementPrizes.map((prize, index) => {
                    return(
                    <Fragment key={"gp_increment_prizes1_" + index}>
                    <FormRow data={prize}>
                        <FormTextInput width="3" name={"gp_increment_top_num_" + index} placeholder="Top #" label="* Top #" required={true} feedbackType="invalid" feedbackText="Please enter a Top Number of Competitors" value={prize["top_num"]} onChange={(e) => {onChangePrizes(e, index, "increment", gradedPlacingIncrementPrizes, setGradedPlacingIncrementPrizes, "gp_increment_")}}/>
                        <FormTextInput width="3" name={"gp_increment_top_percent_" + index} placeholder="Top %" label="* Top %" required={true} feedbackType="invalid" feedbackText="Please enter a Top Percent of Competitors" value={prize["top_percent"]} onChange={(e) => {onChangePrizes(e, index, "increment", gradedPlacingIncrementPrizes, setGradedPlacingIncrementPrizes, "gp_increment_")}}/>
                    </FormRow>
                    <FormRow data={prize}>
                        <FormSelect width="3" name={"gp_increment_prize_type_id_" + index} placeholder="Prize Type" label="* Prize Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type" value={prize["prize_type_id"]} onChange={(e) => {onChangePrizes(e, index, "increment", gradedPlacingIncrementPrizes, setGradedPlacingIncrementPrizes, "gp_increment_")}}>
                            {
                                prize_type_data &&
                                prize_type_data.map((prize_type) => {
                                    return(
                                        <option key={prize_type.id} value={prize_type.id}>{prize_type.name}</option>
                                    )
                                })
                            }
                        </FormSelect>
                        <FormTextInput width="3" name={"gp_increment_prize_type_value_" + index} placeholder="Value" label="* Value" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Type Value" value={prize["prize_type_value"]} onChange={(e) => {onChangePrizes(e, index, "increment", gradedPlacingIncrementPrizes, setGradedPlacingIncrementPrizes, "gp_increment_")}}/>
                        <FormSelect width="3" name={"gp_increment_prize_type_value_type_" + index} placeholder="Value Type" label="* Value Type" required={true} feedbackType="invalid" feedbackText="Please select a Prize Type Value Type" value={prize["prize_type_value_type"]} onChange={(e) => {onChangePrizes(e, index, "increment", gradedPlacingIncrementPrizes, setGradedPlacingIncrementPrizes, "gp_increment_")}}>
                            <option value="int">Integer</option>
                            <option value="float">Float</option>
                            <option value="string">String</option>
                        </FormSelect>
                        <div className="col-md-2" style={{marginTop: "22px"}}><OnClickButton symbolClassName={"fas"} text="" symbol="&#xf2ed;" onClickHandler={(e) => {e.preventDefault();  onClickDeletePrize(e, index, gradedPlacingIncrementPrizes, setGradedPlacingIncrementPrizes)}}/> </div>
                    </FormRow>
                    </Fragment>
                    )
                })}
                <div>
                    <OnClickButton text="+ Add Graded Placing Increment Prize" onClickHandler={(e) => onClickAddPrize(e, "gp_increment")}/>
                </div>
                </>
            }
            </>
        </FormRow>

        </BasicAddForm>
    )
}


export default AddOffRiflePrizeTemplateForm