/*-------------------------------------------------------------------
|  🐼 React FC Input
|
|  🦝 Todo: CREATE RE-USEABLE INPUT COMPOENT
|
|  🐸 Returns:  JSX
*-------------------------------------------------------------------*/

import cn from 'classnames'
import { findInputError, isFormInvalid } from '../utils'
import { useFormContext } from 'react-hook-form'
import { AnimatePresence, motion } from 'framer-motion'
import { MdError } from 'react-icons/md'

export const Input = ({label, type, id, placeholder}) => {
  const { register, formState: { errors }, } = useFormContext()
  const inputError = findInputError(errors, label)
  const isInvalid = isFormInvalid(inputError)

  return (
    <>
    <div className="form-floating">
      
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        className="form-control" 
        {...register(label, {
          required: {
            value: true,
            message: 'Required',
          },
        })}
      />
      <label htmlFor={id}>
        {label + placeholder}
      </label>
      <AnimatePresence mode="wait" initial={false}>
      {isInvalid && (
        <InputError
          message={inputError.error.message}
          key={inputError.error.mdddessage}
        />
      )}
      </AnimatePresence>
    </div>
    
    </>
  );
}

const InputError = ({message}) => {
  return (
    <motion.p
      className="login-form-error"
      {...framer_error}
    >
      <MdError />
      {message}
    </motion.p>
  )
}

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
}
