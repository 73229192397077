import { formatDateTextDayName } from "../../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../../utils/eventUtils"
import EventMatchLayout from "./EventMatchLayout"
import OnClickButton from "../../OnClickButton"
import AddMatchForm from "../../forms/events/AddMatchForm"
import { useModal } from "../../../utils/hooks/useModal"
import { postData } from "../../../api/postData"
import { useAuth } from "../../../utils/hooks/useAuth"


function EventDayLayout({data=null, children})
{
    const url = "/" + process.env.REACT_APP_CLUB + "/events/" + data.event_id + "/days/" + data.id + "/matches"
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()
    const {logout} = useAuth()

    const handleClickAdd = (event, modal, modalDataKey, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    let modalData={
        "add": {
            title:"Add Match", 
            data: <AddMatchForm distances={data.venue.distances} />, 
            callback: (formData) => addMatch(formData)
        },
    }

    const addMatch = async (formData) => {
        let request_json = {
            name: formData.name,
            distance_id: parseInt(formData.distance_id),
            or_prize_template_id: parseInt(data.or_prize_template_id),
            h_prize_template_id: parseInt(data.h_prize_template_id),
            prizes: "[]"
        }

        console.log(url)

        let response = await postData(url, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            return response
        }
    }

    return(
        <>
        <div className="event-day-container">
            <div className="event-day-title-bar">
                <div className="event-day-title"><strong>Day:</strong> {data.name}</div>
                <div className="event-day-title"><strong>Venue:</strong> {data.venue.name}</div>
                {/* <div className="event-day-button"><OnClickButton text="Add Match" onClickHandler={(e) => handleClickAdd(e,true, "add", null)} permission_max_level={2}/></div> */}
            </div>
            {
                data.matches.map((match) => 
                    <EventMatchLayout key={match.id} data={match} />
                )
            }
        </div>
        {children}
        </>
    )

}

export default EventDayLayout