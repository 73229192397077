import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useContext, useEffect } from 'react';
import { api_get } from '../../../api/api';
import { useAuth } from '../../../utils/hooks/useAuth';
import { useGetData } from '../../../api/hooks/useGetData';
import { useModal } from '../../../utils/hooks/useModal';

const normal_url = "/" + process.env.REACT_APP_CLUB + "/members/"
const superadmin_url = "/na/members/"

const EditMemberForm = () => {
    const {user, logout} = useAuth()

    const {modalSave, currentModalTarget} = useModal()
    const [validated, setValidated] = useState(false);

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    let url = normal_url
    if(currentModalTarget === user.username && user.user_role === "superadmin")
    {
      url = superadmin_url
    }
    useGetData(url + currentModalTarget + "/", setLoading, setData)


    const handleSubmit = (event) => {
        
        const form = event.currentTarget;
        checkPasswords(form)

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
        
    };

    const checkPasswords = (form) => {
      const password = document.getElementById("validationCustomPassword")
      const password2 = document.getElementById("validationCustomPassword2")
      if(password != password2)
      {
        password2.setCustomValidity("Password Error")
      }
    } 

    if(data)
    {
    return (
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="edit-form-username">
            Edit {data.username}
            <input type="hidden" name="username" value={data.username} />
          </div>
          <Row className="mb-2">
            <Form.Group as={Col} md="8" controlId="validationCustomFullName">
              <Form.Label>* Full Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Full name"
                name="full_name"
                defaultValue={data.user.full_name}
              />
              <Form.Control.Feedback type="invalid">Please provide a First name</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                defaultValue={data.user.email}
              />
              <Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomRole">
              <Form.Label>* User Role</Form.Label>
              <Form.Control as="select" aria-label="Default select example" name="user_role" required defaultValue={data.user.user_role}>
                <option value="">Select Role</option>
                {user.role.level == 1 && <option value="superadmin">Super Admin</option>}
                <option value="clubmember">Club Member</option>
                {user.role.level <= 2 && <option value="clubadmin">Club Admin</option>}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please select user role</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
                defaultValue={data.address1}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomAddress2">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="address2"
                defaultValue={data.address2}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="4" controlId="validationCustomSuburb">
              <Form.Label>City/Suburb/Town</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="suburb"
                defaultValue={data.suburb}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomState">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                defaultValue={data.state}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomPostcode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postcode"
                name="postcode"
                defaultValue={data.postcode}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                name="phone"
                defaultValue={data.phone}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomNRAAID">
              <Form.Label>NRAA SID</Form.Label>
              <Form.Control
                type="text"
                placeholder="NRAA SID"
                name="nraa_sid"
                defaultValue={data.nraa_sid}
              />
            </Form.Group>
          </Row>
        </Form>
      );
    }
}

export default EditMemberForm