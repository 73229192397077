import { useAuth } from "../utils/hooks/useAuth"

function ProtectedNavLink({id=null, permission_max_level=3, permission_id_override=null, children})
{
    const {user} = useAuth()

    if(user.role.level <= permission_max_level || (id != null && permission_id_override === id))
    {
        return (
                children
        )
    }
    else
    {
        return
    }
    
}

export default ProtectedNavLink