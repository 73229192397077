import { Link, Outlet, NavLink} from "react-router-dom";
import ALink from "../../components/ALink";
import ProtectedNavLink from "../../components/ProtectedNavLink";

function ClubDetailsHome(){
    return (
        <>
            <nav className="nav-sub-header">
                <div className="left-header">
                    <NavLink to="/home/clubdetails/" end>Club Details</NavLink>
                    <ProtectedNavLink permission_max_level={2}><NavLink to="/home/clubdetails/venues">Venues</NavLink></ProtectedNavLink>
                    <ProtectedNavLink permission_max_level={2}><NavLink to="/home/clubdetails/seasons">Seasons</NavLink></ProtectedNavLink>
                    <ProtectedNavLink permission_max_level={2}><NavLink to="/home/clubdetails/grades">Grade Templates</NavLink></ProtectedNavLink>
                </div>
            </nav>
            
            <div className="content">
                <Outlet />
            </div>
            
        </>
    );
}

export default ClubDetailsHome