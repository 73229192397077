export function getCompetitor(id, memberArr)
{
    for(let i=0; i < memberArr.length; i++)
    {
        let member = memberArr[i]
        for(let j=0; j < member.competitors.length; j++)
        {
            if (member.competitors[j].id === id)
            {
                return member.competitors[j]
            }
        }
    }
    return null
}