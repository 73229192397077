import { Link } from "react-router-dom";

export function LoginButton({onLoginClick})
{
    return (
        <>
            <button onClick={onLoginClick}>Login</button>
        </>
    );
}

export function LogoutButton({onLogoutClick})
{
    return (
        <>
            <Link onClick={onLogoutClick}>Logout</Link>
        </>
    );
}