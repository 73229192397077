import Row from 'react-bootstrap/Row';
import React from 'react';

function FormRow({bottomMargin=4, children, data=null}) {

    return(
        <Row className={"mb-" + bottomMargin}>
            {
                React.Children.map(children, child => React.cloneElement(child, {data: data}))
            }
        </Row>
    )
}

export default FormRow