import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/hooks/useAuth";

export const AdminRoute = ({ children }) => {
  const { user } = useAuth();
  if (user.role.level > 2) {
    // user is not an admin
    return "You do not have access to this page";
  }
  return children;
};