import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

function FormCheckBox({width=4, label="", name="", defaultChecked=false, onChange=null, defaultValueField=null, data=null, checkedModifier=null}) {
    let defaultValue = defaultChecked
    if(data && defaultValueField)
    {
        defaultValue = data[defaultValueField]
        if(checkedModifier)
        {
            defaultValue = checkedModifier(defaultValue)
        }
    }

    return(
        <Form.Group as={Col} md={width} className="mb-3">
            <Form.Check
                label={label}
                name={name}
                defaultChecked={defaultValue}
                onChange={onChange}
                id={name}
            />
        </Form.Group>
    )
}

export default FormCheckBox