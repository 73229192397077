
export function getBodyData(data, fields, idField, urlFields)
{
    let bodyData = []
    if(data)
    {
        
        for(let i=0; i < data.length; i++)
        {
            bodyData[i] = {id: data[i][idField], items: []}

            for(let j=0; j < fields.length; j++)
            {
                let field_arr = fields[j].split(".")
                let field_data = data[i]
                field_arr.forEach(element => {
                    field_data = field_data[element]
                });
                bodyData[i].items[j] = {url: urlFields[j], data: field_data}
            }
            
        }
    }

    return bodyData
}
