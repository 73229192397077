import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';

function AddPrizeTypeForm() {
    return(
      <BasicAddForm>
        <FormRow>
          <FormTextInput width="10" name="name" placeholder="Prize Type Name" label="* Prize Type Name" required={true} feedbackType="invalid" feedbackText="Please enter a Prize Type Name"/>     
        </FormRow>
      </BasicAddForm>
    )
}

export default AddPrizeTypeForm