import { useModal } from "../../utils/hooks/useModal"
import Modal from "../Modal"

function ListTableLayout({title="", topButtons=null, children})
{
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    return(
        <>
        <div className="list-table-layout">
            <h3>{title}</h3>
            {topButtons && topButtons}
            {children}
        </div>
        <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >{currentModalData ? currentModalData.data : ""}</Modal>
        </>
    )

}

export default ListTableLayout