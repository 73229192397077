import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormSelect from '../FormSelect';

import OnClickButton from '../../OnClickButton';
import { useState } from 'react';



function AddGradeTemplateForm({classData}) {
  const [currentClassIDs, setCurrentClassIDs] = useState([classData[0].id])

  function getClassDataIndexWithID(id)
  {
      for(let i=0; i < classData.length; i++)
      {
          if(classData[i].id == id)
          {
              return i
          }
      }
      return 0
  }

  const onClickAddClass = (event) => {
    setCurrentClassIDs([...currentClassIDs, classData[0].id])
  }

  const onChangeClass = (event, index) => {
      const rep_currentClassIDs = currentClassIDs.map((currentClassID, c_index) => {
          if(index == c_index)
          {
              return event.target.value
          }
          else
          {
              return currentClassID
          }
      })
      setCurrentClassIDs(rep_currentClassIDs)
  }

    return(
      <BasicAddForm>
        <FormRow>
          <FormTextInput width="5" name="name" placeholder="Grade Name" label="* Grade Name" required={true} feedbackType="invalid" feedbackText="Please enter a Grade Name"/>  
          <FormTextInput width="5" name="short_name" placeholder="Grade Short Name" label="* Grade Short Name" required={true} feedbackType="invalid" feedbackText="Please enter a Grade Short Name"/>   
        </FormRow>
        {currentClassIDs &&
            currentClassIDs.map((currentClassID, index) => (
            <FormRow key={"row" + index}>
                <FormSelect name={"class_" + index} label="* Class" placeholder="Select Class" required={true} feedbackType="invalid" feedbackText="Please Select Class" defaultValue={currentClassID} onChange={(e) => {onChangeClass(e, index)}}>
                    {classData && 
                        classData.map((_class) => (
                            <option key={"class" + _class.id} value={_class.id}>{_class.name}</option>
                        ))
                    }
                </FormSelect>
                <FormSelect name={"sub_class_" + index} label="Sub Class" placeholder="Select Sub Class" required={false} feedbackType="invalid" feedbackText="Please Select Sub Class">
                    {classData[getClassDataIndexWithID(currentClassID)] && 
                        classData[getClassDataIndexWithID(currentClassID)].subclasses.map((subclass) => (
                                <option key={"subclass" + subclass.id} value={subclass.id}>{subclass.name}</option>
                        ))
                    }
                </FormSelect>
            </FormRow>
            ))
          }
          <FormRow>
            <div>
                <OnClickButton text="+ Class" onClickHandler={onClickAddClass}/>
            </div>
          </FormRow>
      </BasicAddForm>
    )
}

export default AddGradeTemplateForm