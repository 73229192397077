import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OnClickButton from '../../OnClickButton';
import { useModal } from '../../../utils/hooks/useModal';

function AddHandicapTypeForm({classData})
{
    const {modalSave} = useModal()
    const [validated, setValidated] = useState(false);
    const [currentClassIDs, setCurrentClassIDs] = useState([classData[0].id])

    function getClassDataIndexWithID(id)
    {
        for(let i=0; i < classData.length; i++)
        {
            if(classData[i].id == id)
            {
                return i
            }
        }
        return 0
    }

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
    };

    const onClickAddClass = (event) => {
        setCurrentClassIDs([...currentClassIDs, classData[0].id])
    }

    const onChangeClass = (event, index) => {
        const rep_currentClassIDs = currentClassIDs.map((currentClassID, c_index) => {
            if(index == c_index)
            {
                return event.target.value
            }
            else
            {
                return currentClassID
            }
        })
        setCurrentClassIDs(rep_currentClassIDs)
    }

    return (
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomName">
              <Form.Label>* Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="e.g. F Class Open"
                  name="name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a name.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          {currentClassIDs &&
            currentClassIDs.map((currentClassID, index) => (
            <Row key={"row" + index} className="mb-2">
                <Form.Group as={Col} md="6" controlId={"validationCustomClass" + index}>
                <Form.Label>* Class</Form.Label>
                <Form.Control as="select" aria-label="Select Class" name={"class_" + index} required defaultValue={currentClassID} onChange={(e) => {onChangeClass(e, index)}}>
                    {classData && 
                        classData.map((_class) => (
                            <option key={"class" + _class.id} value={_class.id}>{_class.name}</option>
                        ))
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select class</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId={"validationCustomSubClass" + index}>
                <Form.Label>Sub Class</Form.Label>
                <Form.Control as="select" aria-label="Select Sub Class" name={"sub_class_" + index}>
                    {classData[getClassDataIndexWithID(currentClassID)] && 
                        classData[getClassDataIndexWithID(currentClassID)].subclasses.map((subclass) => (
                                <option key={"subclass" + subclass.id} value={subclass.id}>{subclass.name}</option>
                        ))
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select a sub class</Form.Control.Feedback>
                </Form.Group>
            </Row>
            ))
          }
          <Row className="mb-2">
            <div>
                <OnClickButton text="+ Class" onClickHandler={onClickAddClass}/>
            </div>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="4" controlId="validationCustomTotalScores">
              <Form.Label>* Total Scores</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Total Scores"
                name="total_scores"
              />
              <Form.Control.Feedback type="invalid">Please provide number of Total Scores</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomBestScores">
              <Form.Label>* Best Scores</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Best Scores"
                name="best_scores"
              />
              <Form.Control.Feedback type="invalid">Please provide number of Best Scores</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomMaxScore">
              <Form.Label>* Max Score</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Max Score e.g. 50"
                name="max_score"
              />
              <Form.Control.Feedback type="invalid">Please provide Max Score</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-2 pt-4">
            <Form.Group as={Col} md="4" className="mb-3">
                <Form.Check
                    label="Use Rounds"
                    name="use_rounds"
                    defaultChecked
                />
            </Form.Group>
            <Form.Group as={Col} md="5" className="mb-3">
                <Form.Check
                    label="Normalize Scores"
                    name="normalize"
                />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="4" controlId="validationCustomNormalizeScore">
              <Form.Label>* Normalize Score</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Normalize Score"
                name="normalize_score"
              />
              <Form.Control.Feedback type="invalid">Please provide a Normalized Score</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomBenchmarkScore">
              <Form.Label>* Benchmark Score</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Benchmark Score"
                name="benchmark_score"
              />
              <Form.Control.Feedback type="invalid">Please provide a Benchmark Score</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomBenchmarkMultiplier">
              <Form.Label>* Multiplier</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Benchmark Multiplier (normally 1)"
                name="benchmark_multiplier"
              />
              <Form.Control.Feedback type="invalid">Please provide Benchmark Multiplier</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
    )
}

export default AddHandicapTypeForm