let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
let monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
let dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export function formatDateForm(date)
{
    let d = new Date(date)
    return [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-')
}

export function formatDate(date)
{
    let d = new Date(date)
    return [('0' + d.getDate()).slice(-2), ('0' + (d.getMonth() + 1)).slice(-2), d.getFullYear()].join("-")
}

function dateOrdinal(dom) {
    if (dom == 31 || dom == 21 || dom == 1) return dom + "st";
    else if (dom == 22 || dom == 2) return dom + "nd";
    else if (dom == 23 || dom == 3) return dom + "rd";
    else return dom + "th";
};

export function formatDateText(date)
{
    let d = new Date(date)
    return dateOrdinal(d.getDate()) + " " + monthNames[(d.getMonth())] + " " + d.getFullYear()
}

export function formatDateTextShort(date)
{
    let d = new Date(date)
    return dateOrdinal(d.getDate()) + " " + monthNamesShort[(d.getMonth())] + " " + d.getFullYear()
}

export function formatDateTextDayName(date)
{
    let d = new Date(date)
    return dayNames[(d.getDay())] + ", " + dateOrdinal(d.getDate()) + " " + monthNames[(d.getMonth())] + " " + d.getFullYear()
}