export function groupClasses(obj)
{
    let ret_classes = []
    let keys = Object.keys(obj)
    for(let i=0; i < keys.length; i++)
    {
        let key_split = keys[i].split("class_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_classes.push({class_id: obj["class_" + key_split[1]], sub_class_id: obj["sub_class_" + key_split[1]]})
            }
        }
    }
    return ret_classes
}