import React from "react"

function BasicBody({edit=false, del=false, children})
{
    return(
        <tbody>
            {children}
        </tbody>
    )

}

export default BasicBody