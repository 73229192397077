export function groupScores(obj)
{
    let ret_scores = []

    let keys = Object.keys(obj)
    for(let i=0; i < keys.length; i++)
    {
        let key_split = keys[i].split("member_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_scores.push({state_sid: obj["member_" + key_split[1]], competitor_id: obj["discipline_" + key_split[1]], score_string: obj["scores_" + key_split[1]]})
            }
        }
    }
    return ret_scores
}