import React from "react";
import TableRow from "./TableRow";
import TableHeadItem from "./TableHead";

const Table = ({ theadData, tbodyData, customClass, url, edit, editHandler, del, deleteHandler, extraOptions=[]}) => {
    return (
        <table className={customClass}>
            <thead>
                <tr>
                    {theadData.map((h) => {
                        return <TableHeadItem key={h} item={h} />;
                    })}
                    {extraOptions && 
                        extraOptions.map((option) => {
                            return <TableHeadItem key={option.name} item="" />
                        })
                    }
                    {edit && <TableHeadItem item="" />}
                    {del && <TableHeadItem item="" />}
                    
                </tr>
            </thead>
            <tbody>
                {tbodyData.map((item) => {
                    return <TableRow key={item.id} id={item.id} data={item.items} url={url} edit={edit} editHandler={editHandler} del={del} deleteHandler={deleteHandler} extraOptions={extraOptions}/>;
                })}
            </tbody>
        </table>
    );
};

export default Table;