import { useState } from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OnClickButton from '../../OnClickButton';
import ALink from "../../ALink";
import { useModal } from "../../../utils/hooks/useModal";

function AddScoreForm({member})
{
    const {modalSave} = useModal()
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true)
            modalSave(event)
        }
        
    };

    return (
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomDate">
              <Form.Label>Date</Form.Label>
                <Form.Control
                    type="date"
                    name="date"
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Please enter a date.
                </Form.Control.Feedback>
            </Form.Group>
          </Row>

        <Row className="mb-2">
            <Form.Group as={Col} md="12" controlId="validationCustomTotalScores">
                <Form.Label>Round Scores (Separated by a comma) <br/>(Do not include V's/X's e.g. 58.07 & 59.08 would be entered as 58,59)</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Scores e.g. 58,59"
                    name="scores"
                />
                <Form.Control.Feedback type="invalid">Please provide Scores</Form.Control.Feedback>
            </Form.Group>
        </Row>
        </Form>
    )
}

export default AddScoreForm