import { formatDateTextDayName } from "../../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../../utils/eventUtils"

function EventInfoLayout({data=null, topButtons=null, or_data=null, hcp_data=null})
{
    return(
        <> 
        <div className="event-container-bar">
            <div className="event-container-date">
                {data && formatDateTextDayName(data.start_date)}
            </div>
            {topButtons && topButtons}
        </div>
        <div className="content-container">
            <div className="content-container-head">{data.name} : {getStatus(data.open, false)}</div>
            <div>
                <div className="content-container-row">
                    <div className="inline pr-15em"><strong>Is Multi-Day Event:</strong> {boolToYesNo(data.is_multi_day)}</div>
                    <div className="inline pr-15em"><strong>Multiple Matches per Day:</strong> {boolToYesNo(data.is_multi_match)}</div>
                </div>
                <div className="content-container-row">
                    <div className="inline pr-15em"><strong>Include in Club Championship:</strong> {boolToYesNo(data.is_championship)}</div>
                    <div className="inline pr-15em"><strong>Counts for Handicap:</strong> {boolToYesNo(data.is_handicapped)}</div>
                </div>
                <div className="content-container-row">
                    {
                        or_data &&
                        <div className="inline pr-15em"><strong>Off Rifle Prize Template:</strong> {getPrizeTemplateWithId(data.or_prize_template_id, or_data).name}</div>
                    }
                    {
                        hcp_data &&
                        <div className="inline pr-15em"><strong>Handicap Prize Template:</strong> {getPrizeTemplateWithId(data.h_prize_template_id, hcp_data).name}</div>
                    }
                </div>
            </div>
        </div>
        </>
    )

}

export default EventInfoLayout