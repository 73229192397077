import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormDateInput from '../FormDateInput';

function AddSeasonForm() {
    return(
      <BasicAddForm>
        <FormRow>
          <FormTextInput width="10" name="name" placeholder="Season Name" label="* Season Name" required={true} feedbackType="invalid" feedbackText="Please enter a Season Name"/>     
        </FormRow>
        <FormRow>
          <FormDateInput width="5" name="start_date" placeholder="Start Date" label="* Start Date" required={true} feedbackType="invalid" feedbackText="Please enter a Start Date"/>
          <FormDateInput width="5" name="end_date" placeholder="End Date" label="* End Date" required={true} feedbackType="invalid" feedbackText="Please enter a End Date"/>
        </FormRow>
      </BasicAddForm>
    )
}

export default AddSeasonForm