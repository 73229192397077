import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OnClickButton from '../../OnClickButton';
import { useGetData } from '../../../api/hooks/useGetData';
import { useModal } from '../../../utils/hooks/useModal';

const url = "/" + process.env.REACT_APP_CLUB + "/venues"

function AddVenueForm() {
    const {modalSave} = useModal()
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
    };


    return(
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="10" controlId="validationName">
              <Form.Label>* Venue Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Venue Name"
                  name="name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a Venue Name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="10" controlId="validationDescription">
              <Form.Label>Venue Description</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Venue Description"
                  name="description"
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Check
                    label="Home Venue"
                    name="is_home"
                    defaultChecked
                />
            </Form.Group>
          </Row>
          
        </Form>
    )
}

export default AddVenueForm