import BasicEditForm from '../BasicEditForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';



function EditCompetitorHcpForm({event_id, day_id, match_id, name}) {
    const url = "/" + process.env.REACT_APP_CLUB + "/events/" + event_id + "/days/" + day_id + "/matches/" + match_id + "/matchresults"
    return(
      <BasicEditForm url={url} title={name + " Handicap"} primaryKeyName={"id"}>
        <FormRow>
          <FormTextInput width="2" name="handicap" placeholder="Handicap" label="* Handicap" required={true} feedbackType="invalid" feedbackText="Please enter a valid Handicap" defaultValueField="handicap"/>     
        </FormRow>
      </BasicEditForm>
    )
}

export default EditCompetitorHcpForm