import React from "react"

function BasicHead({edit=false, del=false, children})
{
    return(
        <thead>
            <tr>
                {children}
                {edit && <th></th>}
                {del && <th></th>}
            </tr>
        </thead>
    )

}

export default BasicHead