import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function FormSelect({width=6, name="", placeholder="", label="", multiple=false, required=false, feedbackType=null, feedbackText="", defaultValue=null, value=null, onChange=null, defaultValueField=null, data=null, defaultValueModifier=null, children})
{
    if(data && defaultValueField)
    {
        defaultValue = data[defaultValueField]
        if(defaultValueModifier)
        {
            defaultValue = defaultValueModifier(defaultValue)
        }
    }
    return (
        <Form.Group as={Col} md={width} controlId={"validation" + name}>
            <Form.Label>{label}</Form.Label>
            {value && onChange ?
                <Form.Control as="select" multiple={multiple} aria-label={placeholder} name={name} required={required} value={value} onChange={onChange}>
                {
                    children
                }
                </Form.Control>
            :
                <Form.Control as="select" multiple={multiple} aria-label={placeholder} name={name} required={required} defaultValue={defaultValue} onChange={onChange}>
                {
                    children
                }
                </Form.Control>
            }
            <Form.Control.Feedback type={feedbackType}>{feedbackText}</Form.Control.Feedback>
        </Form.Group>
    )
}

export default FormSelect