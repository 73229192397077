import {React, useContext} from "react";
import OnClickButton from "../../OnClickButton";
import { useAuth } from "../../../utils/hooks/useAuth";
import { useModal } from "../../../utils/hooks/useModal";

function HandicapTypeDetailContent({data, classData, modalData}) {
    const {modalOpen} = useModal()
    const {user} = useAuth()

    const handleEdit = (e, id) => {
        e.preventDefault();
        modalOpen(modalData["edit"], id)
    }

    function getClassById(id)
    {
        for(let index in classData)
        {
            let _class = classData[index]
            if(_class.id === id)
            {
                return _class
            }
        }
        return null
    }

    function getSubClassById(_class, id) 
    {
        for(let index in _class.subclasses)
        {
            let subclass = _class.subclasses[index]
            if(subclass.id === id)
            {
                return subclass
            }
        }
        return null
    }

    return (
    <div className="card mt-3" style={{width: "300px"}}>
        <div>
            <h5 className="p-2">Handicap Type</h5>
        </div>
        <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Name</h6>
            <span className="text-secondary">{data.name}</span>
        </li>
        {
            data.class_list.map((_class) => {
                let classObj = getClassById(_class.class_id)
                let subClassObj = getSubClassById(classObj, _class.sub_class_id)
                return(
                <li key={_class.class_id} className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="p-0">Class</h6>
                    <span className="text-secondary">{classObj.name + " " + subClassObj.name}</span>
                </li>
                )
            })
        }
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Total Scores</h6>
            <span className="text-secondary">{data.total_scores}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Best Scores</h6>
            <span className="text-secondary">{data.best_scores}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Use Rounds</h6>
            <span className="text-secondary">{data.use_rounds ? "YES" : "NO"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Normalize</h6>
            <span className="text-secondary">{data.normalize ? "YES" : "NO"}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Normalize Score</h6>
            <span className="text-secondary">{data.normalize_score}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Max Score</h6>
            <span className="text-secondary">{data.max_score}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Benchmark Score</h6>
            <span className="text-secondary">{data.benchmark_score}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="p-0">Benchmark Multiplier</h6>
            <span className="text-secondary">{data.benchmark_multiplier}</span>
        </li>
        </ul>
        <OnClickButton text="Edit" id={data.id} onClickHandler={(e) => handleEdit(e, data.id)} permission_max_level={2}/>
    </div>
    )
}

export default HandicapTypeDetailContent