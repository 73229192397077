export function groupPrizes(obj)
{
    let ret_classes = {
        ow_prizes: [],
        op_position_prizes: [],
        op_increment_prizes: [],
        gw_prizes: [],
        gp_position_prizes: [],
        gp_increment_prizes: []
    }
    let keys = Object.keys(obj)
    for(let i=0; i < keys.length; i++)
    {
        let key_split = keys[i].split("ow_prize_type_id_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_classes.ow_prizes.push({prize_type_id: parseInt(obj["ow_prize_type_id_" + key_split[1]]), prize_type_value: obj["ow_prize_type_value_" + key_split[1]], prize_type_value_type: obj["ow_prize_type_value_type_" + key_split[1]]})
            }
        }

        key_split = keys[i].split("op_position_position_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_classes.op_position_prizes.push({position: parseInt(obj["op_position_position_" + key_split[1]]), prize_type_id: parseInt(obj["op_position_prize_type_id_" + key_split[1]]), prize_type_value: obj["op_position_prize_type_value_" + key_split[1]], prize_type_value_type: obj["op_position_prize_type_value_type_" + key_split[1]]})
            }
        }

        key_split = keys[i].split("op_increment_prize_type_id_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_classes.op_increment_prizes.push({top_num: parseInt(obj["op_increment_top_num_" + key_split[1]]), top_percent: parseInt(obj["op_increment_top_percent_" + key_split[1]]), prize_type_id: parseInt(obj["op_increment_prize_type_id_" + key_split[1]]), prize_type_value: obj["op_increment_prize_type_value_" + key_split[1]], prize_type_value_type: obj["op_increment_prize_type_value_type_" + key_split[1]]})
            }
        }

        key_split = keys[i].split("gw_prize_type_id_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_classes.gw_prizes.push({prize_type_id: parseInt(obj["gw_prize_type_id_" + key_split[1]]), prize_type_value: obj["gw_prize_type_value_" + key_split[1]], prize_type_value_type: obj["gw_prize_type_value_type_" + key_split[1]]})
            }
        }

        key_split = keys[i].split("gp_position_position_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_classes.gp_position_prizes.push({position: parseInt(obj["gp_position_position_" + key_split[1]]), prize_type_id: parseInt(obj["gp_position_prize_type_id_" + key_split[1]]), prize_type_value: obj["gp_position_prize_type_value_" + key_split[1]], prize_type_value_type: obj["gp_position_prize_type_value_type_" + key_split[1]]})
            }
        }

        key_split = keys[i].split("gp_increment_prize_type_id_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_classes.gp_increment_prizes.push({top_num: parseInt(obj["gp_increment_top_num_" + key_split[1]]), top_percent: parseInt(obj["gp_increment_top_percent_" + key_split[1]]), prize_type_id: parseInt(obj["gp_increment_prize_type_id_" + key_split[1]]), prize_type_value: obj["gp_increment_prize_type_value_" + key_split[1]], prize_type_value_type: obj["gp_increment_prize_type_value_type_" + key_split[1]]})
            }
        }
    }
    return ret_classes
}