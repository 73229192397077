import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import AddGradeTemplateForm from "../../components/forms/club/AddGradeTemplateForm"
import EditGradeTemplateForm from "../../components/forms/club/EditGradeTemplateForm"
import ListTableLayout from "../../components/layouts/ListTableLayout"
import BasicTable from "../../components/tables/BasicTable"
import BasicHead from "../../components/tables/BasicHead"
import BasicBody from "../../components/tables/BasicBody"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import { useAuth } from "../../utils/hooks/useAuth"
import "./css/club-details.css"

import { groupClasses } from "../../utils/groupClasses"
import { getClassById, getSubClassById } from "../../utils/getClass"

const url = "/" + process.env.REACT_APP_CLUB + "/gradetemplates"

function GradeTemplates(){
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const {logout} = useAuth()
    const [classData, setClassData] = useState(null)

    useGetData("/classes/", null, setClassData)

    useGetData(url, setLoading, setData, reload, setReload)

    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const addGradeTemplate = async (formData) => {

        let classes = groupClasses(formData)

        let request_json = 
        {
            name: formData.name,
            short_name: formData.short_name,
            classes: classes,
        }

        let response = await postData(url, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
        

    }

    const updateGradeTemplate = async (formData) => {
        let classes = groupClasses(formData)

        let request_json = 
        {
            name: formData.name,
            short_name: formData.short_name,
            classes: classes,
        }

        let response = await patchData(url + "/", formData.id, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }

    const deleteGradeTemplate = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Grade Template"))
        {
            let response = await deleteData(url + "/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                setData(data.filter(element => element.id !== id))
                return response
            }
        }
    }


    let modalData={
        "add": {
            title:"Add Grade Template", 
            data: <AddGradeTemplateForm classData={classData}/>, 
            callback: (formData) => addGradeTemplate(formData)
        }, 
        "edit": {
            title:"Edit Grade Template", 
            data: <EditGradeTemplateForm  classData={classData}/>, 
            callback: (formData) => updateGradeTemplate(formData)
        },
    }

    let topButtons = [
        <OnClickButton key="add" text="+ Add Grade Template" onClickHandler={() => handleClick(true, "add", null)} permission_max_level={2}/>
    ]

    return(
        <>
            {loading && <div>Loading...</div>}
            {data &&
                <ListTableLayout title="Grade Templates" topButtons={topButtons}>
                    <BasicTable edit={true} del={true} >
                        <BasicHead>
                            <th>Name</th>
                            <th>Short Name</th>
                            <th>Classes</th>
                        </BasicHead>
                        <BasicBody>
                            {
                                data.map((template) => {
                                    let i = 0
                                    return(
                                        <tr key={template.id}>
                                            <td>{template.name}</td>
                                            <td>{template.short_name}</td>
                                            <td>
                                                <table className="grade-template-class-table">
                                                    <tbody>
                                                    { classData &&
                                                        template.classes.map((_class) => {
                                                            let classObj = getClassById(classData, _class.class_id)
                                                            i++
                                                            return (
                                                                <tr key={"classRow" + i}>
                                                                    <td>Class: {classObj.name}</td>
                                                                    <td>Sub Class: {getSubClassById(classObj, _class.sub_class_id).name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    
                                                    }
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td><ALink className={"fas"} title={"Edit"} href={""} id={template.id} onClick={(e) => handleClickEdit(e,true, "edit", template.id, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                            <td><ALink className={"fas"} title={"Delete"} href={""} id={template.id} onClick={(e) => handleClick(null, null, () => deleteGradeTemplate(e, template.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                        </tr>
                                    )
                                })
                            }
                        </BasicBody>
                    </BasicTable>
                </ListTableLayout>
            }
        </>
    )
}

export default GradeTemplates