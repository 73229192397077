import {React, useState} from "react";
import Table from "./Table";
import Modal from "./Modal";
import OnClickButton from "./OnClickButton";
import { useModal } from "../utils/hooks/useModal";

const ListTableLayout = ({buttons, table, modalData}) => {
    
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (button) => {
        if(button.modal && button.modalDataKey)
        {
            modalOpen(modalData[button.modalDataKey])

        }
        else if(button.clickHandler)
        {
            button.clickHandler()
        }
    }

    return(
        <>
        <div className="list-table-layout">
        { 
            buttons.map((button) => {
                return <OnClickButton key={button.key} text={button.text} onClickHandler={() => handleClick(button)} permission_max_level={button.permission_max_level}/>
            })
        }
        {table && <div className="list-table-table">{table}</div>}
        </div>
        <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} error={modalError}>{currentModalData ? currentModalData.data : ""}</Modal>
        </>
        
    )
};

export default ListTableLayout