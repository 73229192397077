import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import Modal from "../../components/Modal"
import EditClubDetailsForm from "../../components/forms/club/EditClubDetailsForm"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { useAuth } from "../../utils/hooks/useAuth"

const url = "/" + process.env.REACT_APP_CLUB

function ClubDetails(){
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const {logout} = useAuth()

    useGetData(url, setLoading, setData, reload, setReload)

    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }


    const updateClubDetails = async (formData) => {
        let response = await patchData(url, "", formData)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }

    let modalData={
        "edit": {
            title:"Edit Club Details", 
            data: <EditClubDetailsForm clubDetails={data}/>, 
            callback: (formData) => updateClubDetails(formData)
        }, 
    }

    return(
        <>
            {loading && <div>Loading...</div>}
            {data &&
                <>
                <div className="content-container">
                    <div className="content-container-head">{data.name}</div>
                    <div>
                        <div className="content-container-row"><strong>Address: </strong>{data.address1}<br/>{data.address2}</div>
                        <div className="content-container-row">
                            <div className="inline pr-15em">
                                <strong>Suburb/Town: </strong>{data.suburb}
                            </div>
                            <div className="inline pr-15em">
                                <strong>State: </strong>{data.state}
                            </div>
                            <div className="inline">
                                <strong>Postcode: </strong>{data.postcode}
                            </div>
                        </div>
                    </div>
                </div>
                <OnClickButton text="Edit" symbol="&#xf044;" symbolClassName={"fas button-padding-left"}  onClickHandler={() => handleClick(true, "edit", null)} permission_max_level={2}/>
                <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >{currentModalData ? currentModalData.data : ""}</Modal>
                </>
            }
        </>
    )
}

export default ClubDetails