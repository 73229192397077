export function groupMemberDisciplines(obj)
{
    let ret_disciplines = []

    let keys = Object.keys(obj)
    for(let i=0; i < keys.length; i++)
    {
        let key_split = keys[i].split("member_")
        if(key_split.length > 1)
        {
            if(key_split[0] == "")
            {
                ret_disciplines.push({state_sid: obj["member_" + key_split[1]], competitor_id: obj["discipline_" + key_split[1]], grade_id: obj["grade_" + key_split[1]]})
            }
        }
    }
    return ret_disciplines
}