import { api_get } from "./api";

const getMe = async () => {
    try {
      const response = await api_get('/users/me/');
      return response.data
    } catch (error) {
      if(error.response && error.response.status === 401)
      {
        return null
      }
      else
      {
        console.error(error)
      }
    }
    return null
  };

export default await getMe