import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useModal } from '../../utils/hooks/useModal';


function BasicAddForm({children}) {
    const {modalSave} = useModal()
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
    };


    return(
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          {children}
        </Form>
    )
}

export default BasicAddForm