import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import AddEventForm from "../../components/forms/events/AddEventForm"
import EditEventForm from "../../components/forms/events/EditEventForm"
import ListTableLayout from "../../components/layouts/ListTableLayout"
import BasicTable from "../../components/tables/BasicTable"
import BasicHead from "../../components/tables/BasicHead"
import BasicBody from "../../components/tables/BasicBody"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import { useAuth } from "../../utils/hooks/useAuth"
import { formatDateTextShort } from "../../utils/formatDate"
import "./css/events.css"
import { getStatus, getOpenCloseButtonText} from "../../utils/eventUtils"


const url = "/" + process.env.REACT_APP_CLUB + "/events"
const or_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/offrifle"
const hcp_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/handicap"
const venue_url = "/" + process.env.REACT_APP_CLUB + "/venues"
const season_url = "/" + process.env.REACT_APP_CLUB + "/seasons"

const router_url = "/home/events/"

function Events() {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const {logout} = useAuth()

    const [or_data, setORData] = useState(null)
    const [hcp_data, setHcpData] = useState(null)
    const [venue_data, setVenueData] = useState(null)
    const [season_data, setSeasonData] = useState(null)

    useGetData(url, setLoading, setData, reload, setReload)
    useGetData(or_url, setLoading, setORData, reload, setReload)
    useGetData(hcp_url, setLoading, setHcpData, reload, setReload)
    useGetData(venue_url, setLoading, setVenueData, reload, setReload)
    useGetData(season_url, setLoading, setSeasonData, reload, setReload)

    const [gradeTemplates, setGradeTemplates] = useState(null)
    
    useGetData("/" + process.env.REACT_APP_CLUB + "/gradetemplates", setLoading, setGradeTemplates)


    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickOpenClose = async (event, id, currentState) => {
        event.preventDefault()
        let new_state = ""
        if(currentState === true)
        {
            console.log("Close Event")
            new_state = "close"
        }
        else
        {
            console.log("Open Event")
            new_state = "open"
        }

        let response = await patchData(url + "/" + id + "/" + new_state, "")
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }

    const addEvent = async (formData) => {
        let start_date = new Date(formData.start_date).toISOString()
        let end_date = null
        let is_multi_day = false
        let is_multi_match = false
        let is_championship = false
        let is_handicapped = false
        let grade_ids = (formData.selected_grades).split(",")

        let grades_array = []
        for(let i=0; i < grade_ids.length; i++)
        {
            let grade_id = grade_ids[i]
            for(let j=0; j < gradeTemplates.length; j++)
            {
                if(gradeTemplates[j].id == grade_id)
                {
                    grades_array.push(gradeTemplates[j])
                } 
            }
        }


        if(formData.end_date)
            end_date = new Date(formData.end_date).toISOString()
        else
            end_date = new Date(formData.start_date).toISOString()

        if(formData.is_multi_day)
            is_multi_day = true

        if(formData.is_multi_match)
            is_multi_match = true

        if(formData.is_championship)
            is_championship = true

        if(formData.is_handicapped)
            is_handicapped = true


        let request_json = {
            name: formData.name,
            season_id: parseInt(formData.season_id),
            venue_id: parseInt(formData.venue_id),
            distance_id: parseInt(formData.distance_id),
            open: false,
            is_multi_day: is_multi_day,
            is_multi_match: is_multi_match,
            start_date: start_date,
            end_date: end_date,
            grades: JSON.stringify(grades_array),
            is_championship: is_championship,
            is_handicapped: is_handicapped,
            or_prize_template_id: parseInt(formData.or_prize_template_id),
            h_prize_template_id: parseInt(formData.h_prize_template_id),
            prizes: "[]"
        }

        let response = await postData(url, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
        

    }

    const updateEvent = async (formData) => {
        console.log(formData)
        let start_date = new Date(formData.start_date).toISOString()
        let end_date = null
        let is_multi_day = false
        let is_multi_match = false
        let is_championship = false
        let is_handicapped = false
        let grade_ids = (formData.selected_grades).split(",")

        let grades_array = []
        for(let i=0; i < grade_ids.length; i++)
        {
            let grade_id = grade_ids[i]
            for(let j=0; j < gradeTemplates.length; j++)
            {
                if(gradeTemplates[j].id == grade_id)
                {
                    grades_array.push(gradeTemplates[j])
                } 
            }
        }


        if(formData.end_date)
            end_date = new Date(formData.end_date).toISOString()
        else
            end_date = new Date(formData.start_date).toISOString()

        if(formData.is_multi_day)
            is_multi_day = true

        if(formData.is_multi_match)
            is_multi_match = true

        if(formData.is_championship)
            is_championship = true

        if(formData.is_handicapped)
            is_handicapped = true


        let request_json = {
            name: formData.name,
            open: false,
            is_multi_day: is_multi_day,
            is_multi_match: is_multi_match,
            start_date: start_date,
            end_date: end_date,
            grades: JSON.stringify(grades_array),
            is_championship: is_championship,
            is_handicapped: is_handicapped,
            or_prize_template_id: parseInt(formData.or_prize_template_id),
            h_prize_template_id: parseInt(formData.h_prize_template_id),
        }

        console.log(request_json)

        let response = await patchData(url + "/", formData.id, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }

    const deleteEvent = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Event"))
        {
            let response = await deleteData(url + "/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                setData(data.filter(element => element.id !== id))
                return response
            }
        }
    }



    let modalData={
        "add": {
            title:"Add Event", 
            data: <AddEventForm off_rifle_prize_templates={or_data} handicap_prize_templates={hcp_data} seasons={season_data} venues={venue_data}/>, 
            callback: (formData) => addEvent(formData)
        }, 
        "edit": {
            title:"Edit Event", 
            data: <EditEventForm off_rifle_prize_templates={or_data} handicap_prize_templates={hcp_data} seasons={season_data} venues={venue_data} />, 
            callback: (formData) => updateEvent(formData)
        },
    }

    let topButtons = [
        <OnClickButton key="add" text="+ Add Event" onClickHandler={() => handleClick(true, "add", null)} permission_max_level={2}/>
    ]

    

    return(
        <>
            {loading && <div>Loading...</div>}
            {data &&
                <ListTableLayout title="Events" topButtons={topButtons}>
                    <BasicTable edit={true} del={true} >
                        <BasicHead>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th></th>
                        </BasicHead>
                        <BasicBody>
                            {
                                data.map((event) => {
                                    return(
                                        <tr key={event.id}>
                                            <td><ALink title="Event Details" href={router_url + event.id}>{event.name}</ALink></td>
                                            <td>{formatDateTextShort(event.start_date)}</td>
                                            <td>{formatDateTextShort(event.end_date)}</td>
                                            <td>{getStatus(event.open)}</td>
                                            <td><ALink className={""} title={getOpenCloseButtonText(event.open)} href={""} id={event.id} onClick={(e) => handleClickOpenClose(e, event.id, event.open)} permission_max_level={2}>{getOpenCloseButtonText(event.open)}</ALink></td>
                                            <td><ALink className={"fas"} title={"Edit"} href={""} id={event.id} onClick={(e) => handleClickEdit(e,true, "edit", event.id, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                            <td><ALink className={"fas"} title={"Delete"} href={""} id={event.id} onClick={(e) => handleClick(null, null, () => deleteEvent(e, event.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                        </tr>
                                    )
                                })
                            }
                        </BasicBody>
                    </BasicTable>
                </ListTableLayout>
            }
        </>
    )
}

export default Events