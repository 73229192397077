import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OnClickButton from '../../OnClickButton';
import { useGetData } from '../../../api/hooks/useGetData';
import { useModal } from '../../../utils/hooks/useModal';

const url = "/" + process.env.REACT_APP_CLUB + "/venues"

function AddDistanceForm() {
    const {modalSave, currentModalTarget} = useModal()
    const [validated, setValidated] = useState(false);

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    useGetData(url +"/" + currentModalTarget, setLoading, setData)

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
    };

    return(
        <>
        {loading && <div>Loading</div>}
        {data &&
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="edit-form-id">
                Venue: {data.name}
                <input type="hidden" name="venue_id" value={data.id} />
            </div>
          <Row className="mb-2">
            <Form.Group as={Col} md="10" controlId="validationName">
              <Form.Label>* Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Name (e.g. 300 Yards)"
                  name="name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a Name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="10" controlId="validationShortName">
              <Form.Label>* Short Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Short Name (e.g. 300Y)"
                  name="short_name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a Short Name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
        </Form>
        }
        </>
    )
}

export default AddDistanceForm