import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { formatDateForm } from '../../utils/formatDate';

function FormDateInput({width=10, name="", placeholder="", label="", labelModifier=null, labelId="", required=false, feedbackType=null, feedbackText="", defaultValueField=null, disabled=false, disabledModifier, data=null}) {
    let defaultValue = null
    if(data)
    {
        defaultValue = formatDateForm(data[defaultValueField])
    }

    if(labelModifier)
    {
        label = labelModifier(label, data)
    }

    if(disabledModifier)
    {
        disabled = disabledModifier(disabled, data)
    }

    return(
        <Form.Group as={Col} md={width} controlId={"validation" + name}>
            <Form.Label id={labelId}>{label}</Form.Label>
            <InputGroup hasValidation>
                <Form.Control
                  type="date"
                  placeholder={placeholder}
                  name={name}
                  required={required}
                  defaultValue={defaultValue}
                  disabled={disabled}
                />
                {
                    feedbackType &&
                    <Form.Control.Feedback type={feedbackType}>
                        {feedbackText}
                    </Form.Control.Feedback>
                }
            </InputGroup>
        </Form.Group>
    )
}

export default FormDateInput