import React from "react"

function BasicTable({edit=false, del=false, children})
{
    return(
        <div className="content-table">
            <table className="basic-table">
                {
                    React.Children.map(children, child => React.cloneElement(child, {edit: edit, del: del}))
                }
            </table>
        </div>
    )

}

export default BasicTable