import BasicEditForm from '../BasicEditForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormDateInput from '../FormDateInput';

const url = "/" + process.env.REACT_APP_CLUB + "/seasons"

function EditSeasonForm() {
    return(
      <BasicEditForm url={url} titleFieldName={"name"} primaryKeyName={"id"}>
        <FormRow>
          <FormTextInput width="10" name="name" placeholder="Season Name" label="* Season Name" required={true} feedbackType="invalid" feedbackText="Please enter a Season Name" defaultValueField="name"/>     
        </FormRow>
        <FormRow>
          <FormDateInput width="5" name="start_date" placeholder="Start Date" label="* Start Date" required={true} feedbackType="invalid" feedbackText="Please enter a Start Date" defaultValueField="start_date"/>
          <FormDateInput width="5" name="end_date" placeholder="End Date" label="* End Date" required={true} feedbackType="invalid" feedbackText="Please enter a End Date" defaultValueField="end_date"/>
        </FormRow>
      </BasicEditForm>
    )
}

export default EditSeasonForm