import { useEffect, useState } from "react"
import { api_delete } from "./api"

export async function deleteData(url, primary_key){

    let data = null
    let error = null
    let loading = false
 
    try
    {
        loading = true
        const response = await api_delete(url + primary_key)
        data = response.data
    }
    catch(_error)
    {
        error = _error
    }
    finally
    {
        loading = false
    }
            
    return {data, error, loading}
}