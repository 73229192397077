/*-------------------------------------------------------------------
|  🐼 React FC Form
|
|  🦝 Todo: CREATE AN AWESOME AND MAINTAINABLE FORM COMPONENT 
|
|  🐸 Returns:  JSX
*-------------------------------------------------------------------*/

import { Input } from './Input.js'
import { FormProvider, useForm } from 'react-hook-form'
import { useState} from 'react'
import "../pages/users/css/sign-in.css"
import { useNavigate} from "react-router-dom";
import { api_post } from '../api/api.js'
import { useAuth } from '../utils/hooks/useAuth.js'
import getme from '../api/getme.js';

export const LoginForm = () => {
  const methods = useForm()
  const navigate = useNavigate();

  const [formError, setFormError] = useState("")

  const {login} = useAuth()

  const doLogin = async (data) => {


      try {
        let response = await api_post("/users/token/", data, {withCredentials: true, headers: {'content-type': 'application/x-www-form-urlencoded'}});
        if(response)
        {
          let me = await getme()
          await login(me)
        }
        
      } catch (error) {
        if(error.response && error.response.status === 401)
        {
          setFormError(error.response.data.detail)
        }
        else
        {
          console.log(error);
        } 
        
      }
  };

  const onSubmit = methods.handleSubmit(data => {
    let new_data = {username: data.Username, password: data.Password}
    doLogin(new_data)
  })

  return (
    <>
    <div className="login-content">
      <div className="form-signin w-100 m-auto">
        <FormProvider {...methods}>
          <form 
            onSubmit={e => e.preventDefault()}
            noValidate
            className="container"
          >
            <img className="mb-4 center-image" src="/images/MRC_Logo_Transparent_600.png" alt="" width="120" />
            <h1 className="h3 mb-3 fw-normal center-text">Please sign in</h1>
            <div className='form-error'>{formError}</div>
            <Input
              label="Username"
              type="text"
              id="username"
              placeholder=" (Try your State ID)"
            />
            <Input
              label="Password"
              type="password"
              id="password"
              placeholder=""
            />
            <button className="btn btn-primary w-100 py-2" onClick={onSubmit}>Sign in</button>
            <p className="mt-5 mb-3 text-body-secondary center-text">Shooter Manager &copy; 2024</p>
          </form>
        </FormProvider>
      </div>
    </div>
    </>
  );
}
