import { useState } from "react"


function ShotCell({round_num, index, value, isSighter=false, isSelected=false, allowedInput, onChange, onSighterConvert=null, onSighterUnConvert=null, className=null})
{
    //const [selected, setSelected] = useState(isSelected)
    
    //console.log(isSelected)
    let backgroundColor = "white"
    if(isSelected)
        backgroundColor = "yellow"

    function handleFocus(event)
    {
        event.target.select()
    }

    function handleDoubleClick(event)
    {
        if(isSighter)
        {
            if(isSelected == false)
            {
                isSelected = true
                onSighterConvert(event, index, round_num)  
            }
            else
            {
                isSelected = false
                onSighterUnConvert(event, index, round_num)
            }

        }
        
    }

    function handleInput(event)
    {
        event.target.value = event.target.value.toUpperCase()
        if(!allowedInput.includes(event.target.value))
        {
            event.target.value = "0"
        }
    }

    return(
        <>
        {
            //console.log(backgroundColor)
        }
        <input id={className + "_" + index} className={className} style={{"backgroundColor": backgroundColor}} size={"1"} type="text" value={value} onFocus={handleFocus} onDoubleClick={handleDoubleClick} onChange={e => onChange(e, index, round_num)} onInput={handleInput}/>
        </>
   )
}

export default ShotCell