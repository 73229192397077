import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import EditEventForm from "../../components/forms/events/EditEventForm"
import ListTableLayout from "../../components/layouts/EventLayout"
import BasicTable from "../../components/tables/BasicTable"
import BasicHead from "../../components/tables/BasicHead"
import BasicBody from "../../components/tables/BasicBody"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { useAuth } from "../../utils/hooks/useAuth"
import { formatDateTextDayName } from "../../utils/formatDate"
import "./css/events.css"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../utils/eventUtils"
import { useParams } from "react-router-dom"
import EventLayout from "../../components/layouts/EventLayout"

const url = "/" + process.env.REACT_APP_CLUB + "/events"
const or_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/offrifle"
const hcp_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/handicap"

function EventDetail() {
    let params = useParams()

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const {logout} = useAuth()

    const [class_data, setClassData] = useState(null)
    const [or_data, setORData] = useState(null)
    const [hcp_data, setHcpData] = useState(null)

    useGetData(url + "/" + params.id, setLoading, setData, reload, setReload)
    useGetData("/classes/", setLoading, setClassData, reload, setReload)
    useGetData(or_url, setLoading, setORData, reload, setReload)
    useGetData(hcp_url, setLoading, setHcpData, reload, setReload)

    const [gradeTemplates, setGradeTemplates] = useState(null)
    
    useGetData("/" + process.env.REACT_APP_CLUB + "/gradetemplates", setLoading, setGradeTemplates)


    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickOpenClose = async (event, id, currentState) => {
        event.preventDefault()
        let new_state = ""
        if(currentState === true)
        {
            new_state = "close"
        }
        else
        {
            new_state = "open"
        }

        let response = await patchData(url + "/" + id + "/" + new_state, "")
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }

    const updateEvent = async (formData) => {
        let start_date = new Date(formData.start_date).toISOString()
        let end_date = null
        let is_multi_day = false
        let is_multi_match = false
        let is_championship = false
        let is_handicapped = false
        let grade_ids = (formData.selected_grades).split(",")

        let grades_array = []
        for(let i=0; i < grade_ids.length; i++)
        {
            let grade_id = grade_ids[i]
            for(let j=0; j < gradeTemplates.length; j++)
            {
                if(gradeTemplates[j].id == grade_id)
                {
                    grades_array.push(gradeTemplates[j])
                } 
            }
        }


        if(formData.end_date)
            end_date = new Date(formData.end_date).toISOString()
        else
            end_date = new Date(formData.start_date).toISOString()

        if(formData.is_multi_day)
            is_multi_day = true

        if(formData.is_multi_match)
            is_multi_match = true

        if(formData.is_championship)
            is_championship = true

        if(formData.is_handicapped)
            is_handicapped = true


        let request_json = {
            name: formData.name,
            open: false,
            is_multi_day: is_multi_day,
            is_multi_match: is_multi_match,
            start_date: start_date,
            end_date: end_date,
            grades: JSON.stringify(grades_array),
            is_championship: is_championship,
            is_handicapped: is_handicapped,
            or_prize_template_id: parseInt(formData.or_prize_template_id),
            h_prize_template_id: parseInt(formData.h_prize_template_id),
        }


        let response = await patchData(url + "/", formData.id, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }



    let modalData={
        "edit": {
            title:"Edit Event", 
            data: <EditEventForm off_rifle_prize_templates={or_data} handicap_prize_templates={hcp_data} />, 
            callback: (formData) => updateEvent(formData)
        },
    }

    let topButtons = []
    if(data)
    {
        topButtons = [
            <OnClickButton key="edit" text="Edit Event" onClickHandler={(e) => handleClickEdit(e,true, "edit", data.id, null)} permission_max_level={2}/>,
            <OnClickButton key="open_close" text={getOpenCloseButtonText(data.open, false)} onClickHandler={(e) => handleClickOpenClose(e, data.id, data.open)} permission_max_level={2}/>
        ]
    }


    let middleButtons = []
    if(data)
    {
        middleButtons = [
            <OnClickButton key="add_competitors" text="Add Competitors" onClickHandler={(e) => handleClickEdit(e,true, "edit", data.id, null)} permission_max_level={2}/>,
            <OnClickButton key="add_day" text={getOpenCloseButtonText(data.open, false)} onClickHandler={(e) => handleClickOpenClose(e, data.id, data.open)} permission_max_level={2}/>
        ]
    }

    return(
        <>
            {loading && <div>Loading...</div>}
            {data &&
                <>
                <EventLayout data={data} topButtons={topButtons} class_data={class_data} or_data={or_data} hcp_data={hcp_data} />
                </>
            }
        </>
    )
}

export default EventDetail