import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import AddPrizeTypeForm from "../../components/forms/events/AddPrizeTypeForm"
import EditPrizeTypeForm from "../../components/forms/events/EditPrizeTypeForm"
import ListTableLayout from "../../components/layouts/ListTableLayout"
import BasicTable from "../../components/tables/BasicTable"
import BasicHead from "../../components/tables/BasicHead"
import BasicBody from "../../components/tables/BasicBody"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import { useAuth } from "../../utils/hooks/useAuth"
import { formatDateTextShort } from "../../utils/formatDate"
import "./css/events.css"

const url = "/" + process.env.REACT_APP_CLUB + "/events/prizetypes"

function PrizeTypes() {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const {logout} = useAuth()

    useGetData(url, setLoading, setData, reload, setReload)

    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const addPrizeType = async (formData) => {
        let response = await postData(url, formData)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
        

    }

    const updatePrizeType = async (formData) => {
        let response = await patchData(url + "/", formData.id, formData)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }

    const deletePrizeType = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Prize Type"))
        {
            let response = await deleteData(url + "/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                setData(data.filter(element => element.id !== id))
                return response
            }
        }
    }


    let modalData={
        "add": {
            title:"Add Prize Type", 
            data: <AddPrizeTypeForm />, 
            callback: (formData) => addPrizeType(formData)
        }, 
        "edit": {
            title:"Edit PrizeType", 
            data: <EditPrizeTypeForm />, 
            callback: (formData) => updatePrizeType(formData)
        },
    }

    let topButtons = [
        <OnClickButton key="add" text="+ Add Prize Type" onClickHandler={() => handleClick(true, "add", null)} permission_max_level={2}/>
    ]

    return(
        <>
            {loading && <div>Loading...</div>}
            {data &&
                <ListTableLayout title="Prize Types" topButtons={topButtons}>
                    <BasicTable edit={true} del={true} >
                        <BasicHead>
                            <th>Name</th>
                        </BasicHead>
                        <BasicBody>
                            {
                                data.map((prizeType) => {
                                    return(
                                        <tr key={prizeType.id}>
                                            <td>{prizeType.name}</td>
                                            <td><ALink className={"fas"} title={"Edit"} href={""} id={prizeType.id} onClick={(e) => handleClickEdit(e,true, "edit", prizeType.id, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                            <td><ALink className={"fas"} title={"Delete"} href={""} id={prizeType.id} onClick={(e) => handleClick(null, null, () => deletePrizeType(e, prizeType.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                        </tr>
                                    )
                                })
                            }
                        </BasicBody>
                    </BasicTable>
                </ListTableLayout>
            }
        </>
    )
}

export default PrizeTypes