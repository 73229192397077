import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import AddOffRiflePrizeTemplateForm from "../../components/forms/events/AddOffRiflePrizeTemplateForm"
import EditOffRiflePrizeTemplateForm from "../../components/forms/events/EditOffRiflePrizeTemplateForm"
import AddHandicapPrizeTemplateForm from "../../components/forms/events/AddHandicapPrizeTemplateForm"
import EditHandicapPrizeTemplateForm from "../../components/forms/events/EditHandicapPrizeTemplateForm"
import ListTableLayout from "../../components/layouts/ListTableLayout"
import BasicTable from "../../components/tables/BasicTable"
import BasicHead from "../../components/tables/BasicHead"
import BasicBody from "../../components/tables/BasicBody"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import { useAuth } from "../../utils/hooks/useAuth"
import { formatDateTextShort } from "../../utils/formatDate"
import "./css/events.css"
import { groupPrizes } from "../../utils/groupPrizes"


const or_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/offrifle"
const hcp_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/handicap"
const prize_type_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetypes"

function PrizeTemplates() {
    const [or_data, setORData] = useState(null)
    const [hcp_data, setHcpData] = useState(null)
    const [prize_type_data, setPrizeTypeData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const {logout} = useAuth()

    useGetData(or_url, setLoading, setORData, reload, setReload)
    useGetData(hcp_url, setLoading, setHcpData, reload, setReload)
    useGetData(prize_type_url, setLoading, setPrizeTypeData, reload, setReload)

    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const addOffRiflePrizeTemplate = async (formData) => {
        let prizes = groupPrizes(formData)
        
        let overall_winner = false
        let overall_placings = false
        let graded_winners = false
        let graded_placings = false
        
        let ow_prizes = []
        let op_position_prizes = []
        let op_increment_prizes = []
        let gw_prizes = []
        let gp_position_prizes = []
        let gp_increment_prizes = []

        if(formData.overall_winner && formData.overall_winner === "on")
        {
            overall_winner = true
            ow_prizes = prizes.ow_prizes
        }
        if(formData.overall_placings && formData.overall_placings === "on")
        {
            overall_placings = true
            op_position_prizes = prizes.op_position_prizes
            op_increment_prizes = prizes.op_increment_prizes
        }
        if(formData.graded_winners && formData.graded_winners === "on")
        {
            graded_winners = true
            gw_prizes = prizes.gw_prizes
        }
        if(formData.graded_placings && formData.graded_placings === "on")
        {
            graded_placings = true
            gp_position_prizes = prizes.gp_position_prizes
            gp_increment_prizes = prizes.gp_increment_prizes
        }

        let request_json = {
            name: formData.name,
            overall_winner: overall_winner,
            ow_prizes: ow_prizes,
            overall_placings: overall_placings,
            op_position_prizes: op_position_prizes,
            op_increment_prizes: op_increment_prizes,
            graded_winners: graded_winners,
            gw_prizes: gw_prizes,
            graded_placings: graded_placings,
            gp_position_prizes: gp_position_prizes,
            gp_increment_prizes: gp_increment_prizes
        }

        let response = await postData(or_url, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
        

    }

    const updateOffRiflePrizeTemplate = async (formData) => {
        let prizes = groupPrizes(formData)

        let overall_winner = false
        let overall_placings = false
        let graded_winners = false
        let graded_placings = false
        
        let ow_prizes = []
        let op_position_prizes = []
        let op_increment_prizes = []
        let gw_prizes = []
        let gp_position_prizes = []
        let gp_increment_prizes = []

        if(formData.overall_winner && formData.overall_winner === "on")
        {
            overall_winner = true
            ow_prizes = prizes.ow_prizes
        }
        if(formData.overall_placings && formData.overall_placings === "on")
        {
            overall_placings = true
            op_position_prizes = prizes.op_position_prizes
            op_increment_prizes = prizes.op_increment_prizes
        }
        if(formData.graded_winners && formData.graded_winners === "on")
        {
            graded_winners = true
            gw_prizes = prizes.gw_prizes
        }
        if(formData.graded_placings && formData.graded_placings === "on")
        {
            graded_placings = true
            gp_position_prizes = prizes.gp_position_prizes
            gp_increment_prizes = prizes.gp_increment_prizes
        }

        let request_json = {
            name: formData.name,
            overall_winner: overall_winner,
            ow_prizes: ow_prizes,
            overall_placings: overall_placings,
            op_position_prizes: op_position_prizes,
            op_increment_prizes: op_increment_prizes,
            graded_winners: graded_winners,
            gw_prizes: gw_prizes,
            graded_placings: graded_placings,
            gp_position_prizes: gp_position_prizes,
            gp_increment_prizes: gp_increment_prizes
        }

        let response = await patchData(or_url + "/", formData.id, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }

    const deleteOffRiflePrizeTemplate = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Off Rifle Prize Template"))
        {
            let response = await deleteData(or_url + "/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                setORData(or_data.filter(element => element.id !== id))
                return response
            }
        }
    }

    const addHandicapPrizeTemplate = async (formData) => {
        let prizes = groupPrizes(formData)
        
        let overall_winner = false
        let overall_placings = false
        let graded_winners = false
        let graded_placings = false
        
        let ow_prizes = []
        let op_position_prizes = []
        let op_increment_prizes = []
        let gw_prizes = []
        let gp_position_prizes = []
        let gp_increment_prizes = []

        if(formData.overall_winner && formData.overall_winner === "on")
        {
            overall_winner = true
            ow_prizes = prizes.ow_prizes
        }
        if(formData.overall_placings && formData.overall_placings === "on")
        {
            overall_placings = true
            op_position_prizes = prizes.op_position_prizes
            op_increment_prizes = prizes.op_increment_prizes
        }
        if(formData.graded_winners && formData.graded_winners === "on")
        {
            graded_winners = true
            gw_prizes = prizes.gw_prizes
        }
        if(formData.graded_placings && formData.graded_placings === "on")
        {
            graded_placings = true
            gp_position_prizes = prizes.gp_position_prizes
            gp_increment_prizes = prizes.gp_increment_prizes
        }

        let request_json = {
            name: formData.name,
            overall_winner: overall_winner,
            ow_prizes: ow_prizes,
            overall_placings: overall_placings,
            op_position_prizes: op_position_prizes,
            op_increment_prizes: op_increment_prizes,
            graded_winners: graded_winners,
            gw_prizes: gw_prizes,
            graded_placings: graded_placings,
            gp_position_prizes: gp_position_prizes,
            gp_increment_prizes: gp_increment_prizes
        }

        let response = await postData(hcp_url, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
        

    }

    const updateHandicapPrizeTemplate = async (formData) => {
        let prizes = groupPrizes(formData)

        let overall_winner = false
        let overall_placings = false
        let graded_winners = false
        let graded_placings = false
        
        let ow_prizes = []
        let op_position_prizes = []
        let op_increment_prizes = []
        let gw_prizes = []
        let gp_position_prizes = []
        let gp_increment_prizes = []

        if(formData.overall_winner && formData.overall_winner === "on")
        {
            overall_winner = true
            ow_prizes = prizes.ow_prizes
        }
        if(formData.overall_placings && formData.overall_placings === "on")
        {
            overall_placings = true
            op_position_prizes = prizes.op_position_prizes
            op_increment_prizes = prizes.op_increment_prizes
        }
        if(formData.graded_winners && formData.graded_winners === "on")
        {
            graded_winners = true
            gw_prizes = prizes.gw_prizes
        }
        if(formData.graded_placings && formData.graded_placings === "on")
        {
            graded_placings = true
            gp_position_prizes = prizes.gp_position_prizes
            gp_increment_prizes = prizes.gp_increment_prizes
        }

        let request_json = {
            name: formData.name,
            overall_winner: overall_winner,
            ow_prizes: ow_prizes,
            overall_placings: overall_placings,
            op_position_prizes: op_position_prizes,
            op_increment_prizes: op_increment_prizes,
            graded_winners: graded_winners,
            gw_prizes: gw_prizes,
            graded_placings: graded_placings,
            gp_position_prizes: gp_position_prizes,
            gp_increment_prizes: gp_increment_prizes
        }

        let response = await patchData(hcp_url + "/", formData.id, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReload(true)
            return response
        }
    }

    const deleteHandicapPrizeTemplate = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Handicap Prize Template"))
        {
            let response = await deleteData(hcp_url + "/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                setORData(or_data.filter(element => element.id !== id))
                return response
            }
        }
    }

    let modalData={
        "add_or": {
            title:"Add Off Rifle Prize Template", 
            data: <AddOffRiflePrizeTemplateForm prize_type_data={prize_type_data} />, 
            callback: (formData) => addOffRiflePrizeTemplate(formData)
        }, 
        "edit_or": {
            title:"Edit Off Rifle Prize Template", 
            data: <EditOffRiflePrizeTemplateForm prize_type_data={prize_type_data} />, 
            callback: (formData) => updateOffRiflePrizeTemplate(formData)
        },
        "add_hcp": {
            title:"Add Handicap Prize Template", 
            data: <AddHandicapPrizeTemplateForm prize_type_data={prize_type_data} />, 
            callback: (formData) => addHandicapPrizeTemplate(formData)
        }, 
        "edit_hcp": {
            title:"Edit Handicap Prize Template", 
            data: <EditHandicapPrizeTemplateForm prize_type_data={prize_type_data} />, 
            callback: (formData) => updateHandicapPrizeTemplate(formData)
        },
    }


    let topButtons = [
        <OnClickButton key="add" text="+ Add Off Rifle Prize Template" onClickHandler={() => handleClick(true, "add_or", null)} permission_max_level={2}/>,
        <OnClickButton key="add_hcp" text="+ Add Handicap Prize Template" onClickHandler={() => handleClick(true, "add_hcp", null)} permission_max_level={2}/>
    ]

    return(
        <>
            {loading && <div>Loading...</div>}
            {(or_data && hcp_data) &&
                <>
                <ListTableLayout title="Prize Templates" topButtons={topButtons}>
                    <h4>Off Rifle Prize Templates</h4>
                    <BasicTable edit={true} del={true} >
                        <BasicHead>
                            <th>Name</th>
                        </BasicHead>
                        <BasicBody>
                            {
                                or_data.map((prizeTemplate) => {
                                    return(
                                        <tr key={prizeTemplate.id}>
                                            <td>{prizeTemplate.name}</td>
                                            <td><ALink className={"fas"} title={"Edit"} href={""} id={prizeTemplate.id} onClick={(e) => handleClickEdit(e,true, "edit_or", prizeTemplate.id, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                            <td><ALink className={"fas"} title={"Delete"} href={""} id={prizeTemplate.id} onClick={(e) => handleClick(null, null, () => deleteOffRiflePrizeTemplate(e, prizeTemplate.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                        </tr>
                                    )
                                })
                            }
                        </BasicBody>
                    </BasicTable>
                    <h4>Handicap Prize Templates</h4>
                    <BasicTable edit={true} del={true} >
                        <BasicHead>
                            <th>Name</th>
                        </BasicHead>
                        <BasicBody>
                            {
                                hcp_data.map((prizeTemplate) => {
                                    return(
                                        <tr key={prizeTemplate.id}>
                                            <td>{prizeTemplate.name}</td>
                                            <td><ALink className={"fas"} title={"Edit"} href={""} id={prizeTemplate.id} onClick={(e) => handleClickEdit(e,true, "edit_hcp", prizeTemplate.id, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                            <td><ALink className={"fas"} title={"Delete"} href={""} id={prizeTemplate.id} onClick={(e) => handleClick(null, null, () => deleteHandicapPrizeTemplate(e, prizeTemplate.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                        </tr>
                                    )
                                })
                            }
                        </BasicBody>
                    </BasicTable>
                </ListTableLayout>
            </>
            }
        </>
    )
}

export default PrizeTemplates