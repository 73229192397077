import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useModal } from '../../../utils/hooks/useModal';
import { useGetData } from '../../../api/hooks/useGetData';
import { useAuth } from '../../../utils/hooks/useAuth';

const url = "/users/"

const ChangeMemberPasswordForm = () => {
  const {user, logout} = useAuth()

  const {modalSave, currentModalTarget, modalError} = useModal()
  const [validated, setValidated] = useState(false);

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  useGetData(url + currentModalTarget + "/", setLoading, setData)

  const handleSubmit = (event) => {
      
      const form = event.currentTarget;
      checkPasswords(form)

      if (form.checkValidity() === false) 
      {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      }
      else
      {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        modalSave(event)
      }
      
  };

  const checkPasswords = (form) => {
    const password = document.getElementById("validationCustomPassword")
    const password2 = document.getElementById("validationCustomPassword2")
    if(password.value != password2.value)
    {
      password2.setCustomValidity("Password Error")
      password2.reportValidity()
    }
    else
    {
      password2.setCustomValidity("")
      password2.reportValidity()
    }
  } 

  if(data)
  {
    if(modalError != "")
    {
      document.getElementById("validationCustomCurrentPassword").setCustomValidity("Password Error")
    }
    return (
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="edit-form-id">
            Change {data.full_name} Password
            <input type="hidden" name="id" value={data.username} />
          </div>
          {
            user.username === data.username &&
            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="validationCustomCurrentPassword">
                <Form.Label>* Current Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Current Password"
                  name="current_password"
                />
                <Form.Control.Feedback type="invalid">Please provide current password</Form.Control.Feedback>
              </Form.Group>
            </Row>
          }
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomPassword">
              <Form.Label>* New Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="New Password"
                name="password"
              />
              <Form.Control.Feedback type="invalid">Please provide a Password</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomPassword2">
              <Form.Label>* Enter New Password Again</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password Again"
                name="password2"
              />
              <Form.Control.Feedback type="invalid" >Passwords don't match</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      );
    }
}

export default ChangeMemberPasswordForm