import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function FormTextInput({ width=10, name="", placeholder="", label="", required=false, feedbackType=null, feedbackText="", defaultValueField=null, onChange=null, data=null, value=null}) {
    let defaultValue = null
    if(data)
    {
        defaultValue = data[defaultValueField]
    }
    return(
        <Form.Group as={Col} md={width} controlId={"validation" + name}>
            <Form.Label>{label}</Form.Label>
            {value != null && onChange ?
                <InputGroup hasValidation>
                    <Form.Control
                    type="text"
                    placeholder={placeholder}
                    name={name}
                    required={required}
                    value={value}
                    onChange={onChange}
                    />
                    {
                        feedbackType &&
                        <Form.Control.Feedback type={feedbackType}>
                            {feedbackText}
                        </Form.Control.Feedback>
                    }
                </InputGroup>
                :
                <InputGroup hasValidation>
                    <Form.Control
                    type="text"
                    placeholder={placeholder}
                    name={name}
                    required={required}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    />
                    {
                        feedbackType &&
                        <Form.Control.Feedback type={feedbackType}>
                            {feedbackText}
                        </Form.Control.Feedback>
                    }
                </InputGroup>
            }
        </Form.Group>
    )
}

export default FormTextInput