import { useState } from "react";
import { api_get } from "../api";
import { useAuth } from "../../utils/hooks/useAuth";

export async function useGetData(url, setLoading, setData, reRender = false, setReRender=null) {
    const [run, setRun] = useState(false)
    const {logout} = useAuth()

    if(!run || reRender) {
        setRun(true)
        if(setReRender)
        {
            setReRender(false)
        }

        try
        {
            if(setLoading)
            {
                setLoading(true)
            }
            const response = await api_get(url)
            setData(response.data)
        }
        catch(error)
        {
            console.log(error)
            if(error.response.status === 401)
            {
                logout()
            }
        }
        finally
        {
            if(setLoading)
            {
                setLoading(false)
            }
        }
    }
    return
}