import React from 'react';
import { Children, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useGetData } from '../../api/hooks/useGetData';
import { useModal } from '../../utils/hooks/useModal';


function BasicEditForm({url, children, titleFieldName=null, primaryKeyName=null, title=null}) {
    const {modalSave, currentModalTarget} = useModal()
    const [validated, setValidated] = useState(false);

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    useGetData(url +"/" + currentModalTarget, setLoading, setData)

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
    };

    

    return(
      <>
      {loading && <div>Loading</div>}
      {data &&
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="edit-form-id">
            Edit {title ? title : data[titleFieldName]}
            <input type="hidden" name={primaryKeyName} value={data[primaryKeyName]} />
          </div>
          {
            React.Children.map(children, child => React.cloneElement(child, {data: data}))
          }
        </Form>
        }
        </>
    )
}

export default BasicEditForm