import { useState } from "react";
import { useModal } from "../../../utils/hooks/useModal";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

function EditClubDetailsForm({clubDetails})
{
    console.log(clubDetails)

    const {modalSave, currentModalTarget} = useModal()
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true)
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
        
    };

    if(clubDetails)
    {
        return(
            <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-2">
                <Form.Group as={Col} md="10" controlId="validationName">
                <Form.Label>* Name</Form.Label>
                <InputGroup hasValidation>
                    <Form.Control
                    type="text"
                    placeholder="Club Name"
                    name="name"
                    defaultValue={clubDetails.name}
                    required
                    />
                    <Form.Control.Feedback type="invalid">
                    Please enter Club Name
                    </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>
            </Row>
            <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="validationAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Address"
                    name="address1"
                    defaultValue={clubDetails.address1}
                />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationAddress2">
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control
                    type="text"
                    placeholder=""
                    name="address2"
                    defaultValue={clubDetails.address2}
                />
                </Form.Group>
            </Row>
            <Row className="mb-2">
                <Form.Group as={Col} md="4" controlId="validationSuburb">
                <Form.Label>City/Suburb/Town</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="City"
                    name="suburb"
                    defaultValue={clubDetails.suburb}
                />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustomState">
                <Form.Label>State</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="State"
                    name="state"
                    defaultValue={clubDetails.state}
                />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustomPostcode">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Postcode"
                    name="postcode"
                    defaultValue={clubDetails.postcode}
                />
                </Form.Group>
            </Row>
        </Form>
        )
    }
}

export default EditClubDetailsForm