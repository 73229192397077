import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // You can add other headers like authorization token here
  },
});

// Define common API methods
const api_get = (url, config = {}) => {
    if(!config.withCredentials)
    {
        config.withCredentials = true
    }
    return apiClient.get(url, config);
};

const api_delete = (url, config = {}) => {
    if(!config.withCredentials)
    {
        config.withCredentials = true
    }
    return apiClient.delete(url, config);
    
};

const api_put = (url, data = {}, config = {}) => {
    if(!config.withCredentials)
    {
        config.withCredentials = true
    }
    return apiClient.put(url, data, config);
};

const api_post = (url, data = {}, config = {}) => {
    if(!config.withCredentials)
    {
        config.withCredentials = true
    }
    if(!config.headers)
    {
        config.headers = {"content-type": "application/json"}
    }
    return apiClient.post(url, data, config);

};

const api_patch = (url, data = {}, config = {}) => {
    if(!config.withCredentials)
    {
        config.withCredentials = true
    }
    if(!config.headers)
    {
        config.headers = {"content-type": "application/json"}
    }
    try
    {
        return apiClient.patch(url, data, config);
    }
    catch(error)
    {
        throw error
    }
    
  };

// Export API methods
export { api_get, api_delete, api_put, api_post, api_patch };