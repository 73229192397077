import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function FormHiddenInput({ name="", defaultValueField=null, data=null, defaultValueModifier=null}) {
    let defaultValue = null
    if(data && defaultValueField)
    {
        defaultValue = data[defaultValueField]
        if(defaultValueModifier)
        {
            defaultValue = defaultValueModifier(defaultValue)
        }
    }
    return(
        <input type="hidden" id={name} name={name} defaultValue={defaultValue}/>
    )
}

export default FormHiddenInput