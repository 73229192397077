import { useParams } from "react-router-dom"
import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import HandicapDetailLayout from "../../components/HandicapDetailLayout"
import AddScoreForm from "../../components/forms/members/AddScoreForm"
import { getCompetitor } from "../../utils/getCompetitor"
import { groupScores } from "../../utils/groupScores"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import { useAuth } from "../../utils/hooks/useAuth"
import { patchData } from "../../api/patchData"

const url = "/" + process.env.REACT_APP_CLUB + "/members/"

function MembersHandicapDetail() {
    let params = useParams()
    const {logout} = useAuth()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reRender, setReRender] = useState(false)

    let competitor_url = url + params.username + "/competitors/" + params.id + "/"

    useGetData(competitor_url, setLoading, setData, reRender, setReRender)

    const addScores = async (formData) => 
    {
        let request_arr = []

        let member_score = formData.scores
        let scores = member_score.split(",")

        for(let j=0; j < scores.length; j++)
        {
            let request_arr_item = {}
            let date = new Date(formData.date)
            request_arr_item.date = date.toISOString()
            request_arr_item.state_sid = data.state_sid
            request_arr_item.competitor_id = parseInt(data.id)
            request_arr_item.event_result_id = null
            request_arr_item.aggregate_score = parseInt(scores[j])

            if(data)
            {
                request_arr_item.max_score = data.handicap_type.max_score
            }

            request_arr.push(request_arr_item)
        }
        
        let response = await postData(url + "handicapscores/", JSON.stringify(request_arr))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReRender(true)
        }
        return response
    }

    const deleteScore = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Score"))
        {
            let response = await deleteData(competitor_url + "handicapscores/", id)
            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                setReRender(true)
                return response
            }
        }
    }

    const reCalculateHandicap = async () => {
        let response = await patchData(competitor_url + "calculatehandicap", "")
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setReRender(true)
            return response
        }
    }

    const deleteAllScores = async () => {
        if(window.confirm("Are you sure you want to delete all Scores"))
        {
            let response = await deleteData(competitor_url + "handicapscores", "")
            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                setReRender(true)
                return response
            }
        }
    }

    let modalData={
        "add": {
            title:"Add Score", 
            data: <AddScoreForm members={data}/>, 
            callback: (formData) => addScores(formData)
        }, 
    }

    return(
        <>
        {loading && <div>Loading...</div>}
        {data && 
        <div className="container">
            <HandicapDetailLayout
                contentMenu={{
                    backUrl: "/home/members/handicaps", 
                    backText: "Back to Handicaps",
                    buttons: []
                }}
                competitor={data}
                buttons={[
                    {key: "add_button", text: "+ Add Score", modal: true, modalDataKey: "add", permission_max_level: 2},
                    {key: "recalculate_button", text: "Recalculate Handicap", modal: false, modalDataKey: null, permission_max_level: 2, clickHandler: reCalculateHandicap},
                    {key: "delete_all", text: "Delete All Scores", modal: false, modalDataKey: null, permission_max_level: 1, clickHandler: () => deleteAllScores()},
                ]}
                modalData={modalData}
                deleteHandler={deleteScore}
            >
            </HandicapDetailLayout>
            </div>
            }
        </> 
    )
}

export default MembersHandicapDetail