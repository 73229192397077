import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OnClickButton from '../../OnClickButton';
import { useGetData } from '../../../api/hooks/useGetData';
import { useModal } from '../../../utils/hooks/useModal';

const handicap_types_url = "/" + process.env.REACT_APP_CLUB + "/members/handicaptypes/"

function AddDisciplineForm({state_sid})
{
    const {modalSave} = useModal()
    const [validated, setValidated] = useState(false);

    const [handicapTypeData, setHandicapTypeData] = useState(null)
    const [loading, setLoading] = useState(false)
    useGetData(handicap_types_url, setLoading, setHandicapTypeData)

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
    };

    return (
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="title-form-id">
                Add Discipline for {state_sid}
            </div>
          <Row className="mb-2">
            <Form.Group as={Col} md="10" controlId="validationCustomETargetID">
              <Form.Label>* Electronic Target ID</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Electronic Target ID"
                  name="e_target_id"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter an Electronic Target ID. If not known just use 0
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomHandicapType">
              <Form.Label>* Discipline</Form.Label>
              <Form.Control as="select" aria-label="Select Discipline" name="handicap_type_id" required>
                <option value="">Select Discipline</option>
                {handicapTypeData &&
                    handicapTypeData.map((handicapType) => {
                        return <option key={handicapType.id} value={handicapType.id}>{handicapType.name}</option>
                    })
                }
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please select a Handicap Type</Form.Control.Feedback>
            </Form.Group>
          </Row>
          
        </Form>
    )
}

export default AddDisciplineForm