import { formatDateTextDayName } from "../../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../../utils/eventUtils"

function EventRoundLayout({data=null, children})
{
    return(
        <>
        <div className="event-round-container">
            <div className="event-round-title-bar">
                <div className="event-round-title"><strong>Round:</strong> {data.round_num}</div>
                <div className="event-round-title"><strong>Counters:</strong> {data.counters}</div>
                <div className="event-round-title"><strong>Sighters:</strong> {data.sighters}</div>
            </div>
        </div>
        {children}
        </>
    )

}

export default EventRoundLayout