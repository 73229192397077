import { useModal } from "../../utils/hooks/useModal"
import { formatDateTextDayName } from "../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../utils/eventUtils"
import Modal from "../Modal"
import EventInfoLayout from "./event/EventInfoLayout"
import EventDayLayout from "./event/EventDayLayout"
import { createContext } from "react"
import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"

export const MembersContext = createContext([])
export const EventContext = createContext([])
export const ClassContext = createContext([])

const members_url = "/" + process.env.REACT_APP_CLUB + "/members"

function EventLayout({data=null, topButtons=null, class_data=null, or_data=null, hcp_data=null, children})
{
    const [members, setMembers] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)

    useGetData(members_url, setLoading, setMembers, reload, setReload)

    
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    return(
        <>
        {members &&
        <div className="event-container">
            <EventInfoLayout data={data} topButtons={topButtons} or_data={or_data} hcp_data={hcp_data}/>
            <EventContext.Provider value={data}>
            <ClassContext.Provider value={class_data}>
            <MembersContext.Provider value={members}>
            {
                data.days.map((day) =>
                    <EventDayLayout key={day.id} data={day} />
                )
            }
            </MembersContext.Provider>
            </ClassContext.Provider>
            </EventContext.Provider>
        </div>
        }   
        <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >{currentModalData ? currentModalData.data : ""}</Modal>
        </>
    )

}

export default EventLayout