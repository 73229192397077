import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OnClickButton from '../../OnClickButton';
import { useGetData } from '../../../api/hooks/useGetData';
import { useModal } from '../../../utils/hooks/useModal';

const handicap_types_url = "/" + process.env.REACT_APP_CLUB + "/members/handicaptypes/"

const url = "/" + process.env.REACT_APP_CLUB + "/members/"

function EditDisciplineForm({state_sid})
{
    const {modalSave, currentModalTarget} = useModal()
    const [validated, setValidated] = useState(false);

    const[data, setData] = useState(null)
    const [handicapTypeData, setHandicapTypeData] = useState(null)
    const [loading, setLoading] = useState(false)
    useGetData(handicap_types_url, setLoading, setHandicapTypeData)

    useGetData(url + state_sid +"/competitors/" + currentModalTarget, null, setData)

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
    };

    if(data)
    {
    return (
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="title-form-id">
                <strong>Edit Discipline for:</strong> {data.member.user.full_name} ({state_sid})
            </div>
            <div>
              <strong>Discipine: </strong> {data.handicap_type.name}
            </div>
            <input type="hidden" name="id" value={data.id} />
          <Row className="mb-2">
            <Form.Group as={Col} md="10" controlId="validationCustomETargetID">
              <Form.Label>* Electronic Target ID</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Electronic Target ID"
                  name="e_target_id"
                  defaultValue={data.e_target_id}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter an Electronic Target ID. If not known just use 0
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          
        </Form>
    )
  }
}

export default EditDisciplineForm