import { createContext, useContext, useMemo, useState } from "react";

const ModalContext = createContext()

export const ModalProvider = ({ children }) => 
{
    const [currentModalData, setCurrentModalData] = useState()
    const [showModal, setShowModal] = useState(false)
    const [currentModalTarget, setCurrentModalTarget] = useState()
    const [modalError, setModalError] = useState("")

    const modalOpen = (modalData, modalTarget = null) => {
        setCurrentModalData(modalData)
        setCurrentModalTarget(modalTarget)
        setShowModal(true)
    }

    const modalClose = () => {
        setCurrentModalData(null)
        setCurrentModalTarget(null)
        setShowModal(false)
        setModalError("")
    }

    const modalSave = (e) => {
        let form = document.getElementById("modalForm")
        var data = new FormData(form);
        let formObject = Object.fromEntries(data.entries());
        if(form.checkValidity())
        {
            currentModalData.callback(formObject).then(
                (response) => {
                    if(response.data && response.data.success == false)
                    {
                        setModalError(response.data.message)
                    }
                    else{
                        modalClose()
                    }
                }
                
            )
        }
        
        e.stopPropagation()
    }

    const value = useMemo(
        () => ({
          currentModalData,
          showModal,
          currentModalTarget,
          modalError,
          modalOpen,
          modalClose,
          modalSave
        }),
        [currentModalData, showModal, currentModalTarget, modalError]
    );

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}

export const useModal = () => 
{
    return useContext(ModalContext);
};