import { formatDateTextDayName } from "../../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../../utils/eventUtils"
import EventRoundLayout from "./EventRoundLayout"
import { useState } from "react"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import OnClickButton from "../../OnClickButton"
import { useModal } from "../../../utils/hooks/useModal"
import { useContext } from "react"
import { EventContext } from "../EventLayout"

import EventCompetitorTab from "./EventCompetitorTab"
import EventScoresTab from "./EventScoresTab"
import EventResultsTab from "./EventResultsTab"

import AddCompetitorsForm from "../../forms/events/AddCompetitorsForm"


function EventMatchLayout({data=null, children})
{
    const [key, setKey] = useState("rounds")
    const event = useContext(EventContext)

    return(
        <>
        <div className="event-match-container">
            <div className="event-match-title-bar">
                <div className="event-match-title"><strong>Match:</strong> {data.name}</div>
                <div className="event-match-title"><strong>Distance:</strong> {data.distance.name}</div>
                <div className="event-match-title"><strong>Rounds:</strong> {data.rounds.length}</div>
            </div>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="rounds" title="Rounds">
                    {
                        data.rounds && 
                        data.rounds.map((round) => 
                            <EventRoundLayout key={round.id} data={round} />
                        )
                    }
                </Tab>
                <Tab eventKey="competitors" title="Competitors">
                    <EventCompetitorTab match_id={data.id} day_id={data.day_id} event_id={event.id}/>
                </Tab>
                {event.open ?
                <Tab eventKey="scores" title="Scores">
                    <EventScoresTab match={data}/>
                </Tab>
                :
                <Tab eventKey="results" title="Results">
                    <EventResultsTab match={data}/>
                </Tab>
                }
                <Tab eventKey="prizes" title="Prizes">
                    Prizes
                </Tab>
            </Tabs>
        </div>
        {children}
        </>
    )

}

export default EventMatchLayout