
export function getStatus(isOpen, upperCase = true)
{
    if(isOpen)
        return upperCase ? "OPEN" : "Open"
    return upperCase ? "CLOSED" : "Closed"
}

export function getOpenCloseButtonText(isOpen, upperCase = true)
{
    if(isOpen)
        return upperCase ? "CLOSE EVENT" : "Close Event"
    return upperCase ? "OPEN EVENT" : "Open Event"
}

export function boolToYesNo(bool, upperCase = true)
{
    if(bool)
        return upperCase ? "YES" : "Yes"
    return upperCase ? "NO" : "No"
}

export function getPrizeTemplateWithId(id, prizeTemplates)
{
    for(const template of prizeTemplates)
    {
        if(template.id === id)
        {
            return template
        }
    }
    return {"id": 0, "name": "None"}
}