import { useState } from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OnClickButton from '../../OnClickButton';
import ALink from "../../ALink";
import { useModal } from "../../../utils/hooks/useModal";

function AddScoresForm({members})
{
    const {modalSave} = useModal()
    const [validated, setValidated] = useState(false);
    const [currentScores, setCurrentScores] = useState([])

    function getMemberWithID(id)
    {
        for(let i=0; i < members.length; i++)
        {
            if(members[i].state_sid == id)
            {
                return members[i]
            }
        }
        return null
    }

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true)
            modalSave(event)
        }
        
    };

    const onClickAddMember = (event) => {
        setCurrentScores([...currentScores, {state_sid: 0, competitor_id: 0, score_string: ""}])
    }

    const onClickDeleteMember = (event, index) => {
        let ret_arr = currentScores.filter((element, a_index) => {return a_index !== index})
        setCurrentScores(ret_arr)
    }

    const onChangeMember = (event, index) => {
        const rep_currentScores = currentScores.map((currentScore, c_index) => {
            if(index == c_index)
            {
                return {state_sid: event.target.value, competitor_id: currentScore.competitor_id, score_string: currentScore.score_string}
            }
            else
            {
                return currentScore
            }
        })
        setCurrentScores(rep_currentScores)
    }

    const onChangeCompetitor = (event, index) => {
        const rep_currentScores = currentScores.map((currentScore, c_index) => {
            if(index == c_index)
            {
                return {state_sid: currentScore.state_sid, competitor_id: event.target.value, score_string: currentScore.score_string}
            }
            else
            {
                return currentScore
            }
        })
        setCurrentScores(rep_currentScores)
    }

    const onChangeScores = (event, index) => {
        const rep_currentScores = currentScores.map((currentScore, c_index) => {
            if(index == c_index)
            {
                return {state_sid: currentScore.state_sid, competitor_id: currentScore.competitor_id, score_string: event.target.value}
            }
            else
            {
                return currentScore
            }
        })
        setCurrentScores(rep_currentScores)
    }

    return (
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomDate">
              <Form.Label>Date</Form.Label>
                <Form.Control
                    type="date"
                    name="date"
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Please enter a date.
                </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {currentScores &&
            currentScores.map((currentScore, index) => (
            <Row key={"row" + index} className="mb-2">
                <Form.Group as={Col} md="4" controlId={"validationCustomMemberName" + index}>
                <Form.Label>Member Name</Form.Label>
                <Form.Control as="select" aria-label="Select Member" name={"member_" + index} required value={currentScores[index].state_sid} onChange={(e) => {onChangeMember(e, index)}}>
                    <option value="0">Select Member</option>
                    {
                        members &&
                        members.map((member) => (
                            <option key={"member" + member.state_sid} value={member.state_sid}>{member.user.full_name}</option>
                        ))
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select member</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId={"validationCustomDiscipline" + index}>
                <Form.Label>Discipline</Form.Label>
                <Form.Control as="select" aria-label="Select Discipline" name={"discipline_" + index} required value={currentScores[index].competitor_id} onChange={(e) => {onChangeCompetitor(e, index)}}>
                    <option value="0">Select Discipline</option>
                    {
                        getMemberWithID(currentScores[index].state_sid) && 
                        getMemberWithID(currentScores[index].state_sid).competitors.map((competitor) => (
                            <option key={"competitor" + competitor.id} value={competitor.id}>{competitor.handicap_type.name}</option>
                        ))
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select discipline</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustomTotalScores">
                    <Form.Label>Scores</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Scores"
                        name={"scores_" + index}
                        onChange={(e) => {onChangeScores(e, index)}}
                        defaultValue={currentScores[index].score_string}
                    />
                    <Form.Control.Feedback type="invalid">Please provide Scores</Form.Control.Feedback>
                </Form.Group>
                <Col md="1" style={{marginTop: "35px"}}>
                    <ALink href="" onClick={(e) => {e.preventDefault(); onClickDeleteMember(e, index)}}>X</ALink>
                </Col>
            </Row>
            ))
          }
          <Row className="mb-2">
            <div>
                <OnClickButton text="+ Add Member" onClickHandler={onClickAddMember}/>
            </div>
          </Row>
        </Form>
    )
}

export default AddScoresForm