import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useModal } from '../../../utils/hooks/useModal';

const AddMemberForm = () => {

    const {modalSave} = useModal()
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;
        checkPasswords(form)

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
    };

    const checkPasswords = (form) => {
      const password = document.getElementById("validationCustomPassword")
      const password2 = document.getElementById("validationCustomPassword2")
      if(password.value != password2.value)
      {
        password2.setCustomValidity("Password Error")
        password2.reportValidity()
      }
      else
      {
        password2.setCustomValidity("")
        password2.reportValidity()
      }
    } 

    return (
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomUsername">
              <Form.Label>* Username (State ID)</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Username (State ID)"
                  name="username"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a username.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomFirstName">
              <Form.Label>* First name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="First name"
                name="first_name"
              />
              <Form.Control.Feedback type="invalid">Please provide a First name</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomLastName">
              <Form.Label>* Last name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Last name"
                name="last_name"
              />
              <Form.Control.Feedback type="invalid">Please provide a Last name</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
              />
              <Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomRole">
              <Form.Label>* User Role</Form.Label>
              <Form.Control as="select" aria-label="Default select example" name="user_role" required>
                <option value="">Select Role</option>
                <option value="clubmember">Club Member</option>
                <option value="clubadmin">Club Admin</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please select user role</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomPassword">
              <Form.Label>* Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Password"
                name="password"
                // id="password"
              />
              <Form.Control.Feedback type="invalid">Please provide a Password</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomPassword2">
              <Form.Label>* Enter Password Again</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password Again"
                name="password2"
                // id="password2"
              />
              <Form.Control.Feedback type="invalid" >Passwords don't match</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomAddress2">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="address2"
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="4" controlId="validationCustomSuburb">
              <Form.Label>City/Suburb/Town</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="suburb"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomState">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomPostcode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postcode"
                name="postcode"
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustomPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                name="phone"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustomNRAAID">
              <Form.Label>NRAA SID</Form.Label>
              <Form.Control
                type="text"
                placeholder="NRAA SID"
                name="nraa_sid"
              />
            </Form.Group>
          </Row>
        </Form>
      );
}

export default AddMemberForm