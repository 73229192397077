export function getClassById(classData, id)
{
    for(let index in classData)
    {
        let _class = classData[index]
        if(_class.id === id)
        {
            return _class
        }
    }
    return null
}

export function getSubClassById(_class, id) 
{
    for(let index in _class.subclasses)
    {
        let subclass = _class.subclasses[index]
        if(subclass.id === id)
        {
            return subclass
        }
    }
    return null
}