import { useParams } from "react-router-dom"
import "./css/member-detail.css"
import { useState, useEffect } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import DetailLayout from "../../components/DetailLayout"
import OnClickButton from "../../components/OnClickButton"
import EditHandicapTypeForm from "../../components/forms/members/EditHandicapTypeForm"
import { patchData } from "../../api/patchData"
import HandicapTypeDetailContent from "../../components/details/members/HandicapTypeDetailContent"
import { groupClasses } from "../../utils/groupClasses"
import { useAuth } from "../../utils/hooks/useAuth"



const url = "/" + process.env.REACT_APP_CLUB + "/members/handicaptypes/"

function MemberHandicapTypeDetail() {
    let params = useParams()

    const {user, logout} = useAuth()

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    const [classData, setClassData] = useState(null)

    useGetData("/classes/", null, setClassData)
    useGetData(url + params.id, setLoading, setData)
    

    const updateHandicapType = async (formData) => 
    {
        let classes = groupClasses(formData)

        let use_rounds = false
        let normalize = false

        if(formData.use_rounds && formData.use_rounds == "on")
        {
            use_rounds = true
        }
        if(formData.normalize && formData.normalize == "on")
        {
            normalize = true
        }

        let request_json = 
        {
            name: formData.name,
            classes: classes,
            total_scores: formData.total_scores,
            best_scores: formData.best_scores,
            use_rounds: use_rounds,
            max_score: formData.max_score,
            normalize: normalize,
            normalize_score: formData.normalize_score,
            benchmark_score: formData.benchmark_score,
            benchmark_multiplier: formData.benchmark_multiplier
        }

        let response = await patchData(url, formData.id, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            setData(response.data)
        }
        return response
    }

    let modalData = {
        "edit": {
            title: "Edit Member",
            data: <EditHandicapTypeForm classData={classData}/>,
            callback: (formData) => updateHandicapType(formData)
        }
    }

    return(
        <>
        {loading && <div>Loading...</div>}
        {data && classData && 
        <div className="container">
            <DetailLayout 
                contentMenu={{
                    backUrl: "/home/members/handicaptypes", 
                    backText: "Back to Handicap Types",
                    buttons: 
                    []
                }} 
                modalData={modalData}
                content={<HandicapTypeDetailContent data={data} classData={classData} modalData={modalData}/>}
            >
            </DetailLayout>
            </div>
            }
        </>
    )
}

export default MemberHandicapTypeDetail