const Modal = ({handleClose, show, title, error="", children}) => {
    const showHideClassName = show ? "display-show" : "display-hide"

    return(
        <>
            <div className={"list-layout-modal modal " + showHideClassName} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div id="modal-error" className="modal-error">
                    {error}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                    <button form="modalForm" type="submit" className="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Modal