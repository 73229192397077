import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormSelect from '../FormSelect';
import { useState } from 'react';
import { useModal } from '../../../utils/hooks/useModal';
import Col from 'react-bootstrap/Col';
import ALink from "../../../components/ALink"
import OnClickButton from '../../OnClickButton';

function AddCompetitorsForm({members, grades}) {
    const {modalSave} = useModal()
    const [validated, setValidated] = useState(false);
    const [currentCompetitors, setCurrentCompetitors] = useState([])

    function getMemberWithID(id)
    {
        for(let i=0; i < members.length; i++)
        {
            if(members[i].state_sid == id)
            {
                return members[i]
            }
        }
        return null
    }


    const onClickAddMember = (event) => {
        setCurrentCompetitors([...currentCompetitors, {state_sid: 0, competitor_id: 0, grade_id: 0}])
    }

    const onClickDeleteMember = (event, index) => {
        let ret_arr = currentCompetitors.filter((element, a_index) => {return a_index !== index})
        setCurrentCompetitors(ret_arr)
    }

    const onChangeMember = (event, index) => {
        const rep_currentCompetitors = currentCompetitors.map((currentCompetitor, c_index) => {
            if(index == c_index)
            {
                return {state_sid: event.target.value, competitor_id: currentCompetitor.competitor_id, grade_id: currentCompetitor.grade_id}
            }
            else
            {
                return currentCompetitor
            }
        })
        setCurrentCompetitors(rep_currentCompetitors)
    }

    const onChangeCompetitor = (event, index) => {
        const rep_currentCompetitors = currentCompetitors.map((currentCompetitor, c_index) => {
            if(index == c_index)
            {
                return {state_sid: currentCompetitor.state_sid, competitor_id: event.target.value, grade_id: currentCompetitor.grade_id}
            }
            else
            {
                return currentCompetitor
            }
        })
        setCurrentCompetitors(rep_currentCompetitors)
    }

    const onChangeGrade = (event, index) => {
        const rep_currentCompetitors = currentCompetitors.map((currentCompetitor, c_index) => {
            if(index == c_index)
            {
                return {state_sid: currentCompetitor.state_sid, competitor_id: currentCompetitor.competitor_id, grade_id: event.target.value}
            }
            else
            {
                return currentCompetitor
            }
        })
        setCurrentCompetitors(rep_currentCompetitors)
    }


    return(
      <BasicAddForm>
        {currentCompetitors &&
            currentCompetitors.map((currentCompetitor, index) => (
            <FormRow key={"row" + index}>
                <FormSelect width={4} name={"member_" + index} placeholder="Select Member" label="Member Name" required={true} feedbackType={"invalid"} feedbackText="Please select member" value={currentCompetitors[index].state_sid}  onChange={(e) => {onChangeMember(e, index)}}>
                    <option value="0">Select Member</option>
                    {
                        members &&
                        members.map((member) => (
                            <option key={"member" + member.state_sid} value={member.state_sid}>{member.user.full_name}</option>
                        ))
                    }
                </FormSelect>
                <FormSelect width={4} name={"discipline_" + index} placeholder="Select Discipline" label="Discipline" required={true} feedbackType={"invalid"} feedbackText="Please select discipline" value={currentCompetitors[index].competitor_id} onChange={(e) => {onChangeCompetitor(e, index)}}>
                    <option value="0">Select Discipline</option>
                    {
                        getMemberWithID(currentCompetitors[index].state_sid) && 
                        getMemberWithID(currentCompetitors[index].state_sid).competitors.map((competitor) => (
                            <option key={"competitor" + competitor.id} value={competitor.id}>{competitor.handicap_type.name}</option>
                        ))
                    }
                </FormSelect>
                <FormSelect width={3} name={"grade_" + index} placeholder="Select Grade" label="Grade" required={true} feedbackType={"invalid"} feedbackText="Please select grade" value={currentCompetitors[index].grade_id} onChange={(e) => {onChangeGrade(e, index)}}>
                    <option value="0">Select Grade</option>
                    {
                        grades && 
                        grades.map((grade) => (
                            <option key={"grade" + grade.id} value={grade.id}>{grade.name}</option>
                        ))
                    }
                </FormSelect>
                <Col md="1" style={{marginTop: "35px"}}>
                    <ALink href="" onClick={(e) => {e.preventDefault(); onClickDeleteMember(e, index)}}>X</ALink>
                </Col>
            </FormRow>
            ))
          }
          <FormRow>
            <div>
                <OnClickButton text="+ Add Member" onClickHandler={onClickAddMember}/>
            </div>
          </FormRow>
      </BasicAddForm>


    )
}

export default AddCompetitorsForm