import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import OnClickButton from '../../OnClickButton';
import { useGetData } from '../../../api/hooks/useGetData';
import { useModal } from '../../../utils/hooks/useModal';

const url = "/" + process.env.REACT_APP_CLUB + "/venues"


function EditVenueForm() {
    const {modalSave, currentModalTarget} = useModal()
    const [validated, setValidated] = useState(false);

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    useGetData(url +"/" + currentModalTarget, setLoading, setData)

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;

        if (form.checkValidity() === false) 
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else
        {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          modalSave(event)
        }
        
    };


    return(
      <>
      {loading && <div>Loading</div>}
      {data &&
        <Form id="modalForm" noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="edit-form-id">
            Edit {data.name}
            <input type="hidden" name="id" value={data.id} />
          </div>
          <Row className="mb-2">
            <Form.Group as={Col} md="10" controlId="validationName">
              <Form.Label>* Venue Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Venue Name"
                  name="name"
                  required
                  defaultValue={data.name}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a Venue Name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="10" controlId="validationDescription">
              <Form.Label>Venue Description</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Venue Description"
                  name="description"
                  defaultValue={data.description}
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Check
                    label="Home Venue"
                    name="is_home"
                    defaultChecked={data.is_home}
                />
            </Form.Group>
          </Row>
          
        </Form>
        }
        </>
    )
}

export default EditVenueForm